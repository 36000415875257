import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import Parse from 'parse';
import BadgeManager from '../Badges/BadgeManager';
import { BadgeTypes } from '../Badges/BadgeTypes';
import { Capacitor } from '@capacitor/core';
import mixpanel from 'mixpanel-browser';
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { useApplePushNotifications } from './NotificationSendingHelpers';

interface SeamUserContextType {
  isAdmin: boolean;
  account: Parse.Object | null;
  setAccount: (account: Parse.Object | null) => void;
  refreshAccount: () => void;
  isLoadingAccount: boolean;
  onboardingStep: number;
  setOnboardingStep: (step: number) => void;
  isNewUser: boolean;
  hasUnreadNotifications: boolean;
  first500UserFollowedChannels: Parse.Object[];
  userCreatedChannels: Parse.Object[];
}

interface SeamUserProviderProps {
  children: ReactNode;
}

export const SeamUserContext = createContext<SeamUserContextType>({
  isAdmin: false,
  account: null,
  setAccount: () => { },
  refreshAccount: () => { },
  isLoadingAccount: false,
  onboardingStep: 0,
  setOnboardingStep: () => { },
  isNewUser: false,
  hasUnreadNotifications: false,
  first500UserFollowedChannels: [],
  userCreatedChannels: [],
});

export const SeamUserProvider: React.FC<SeamUserProviderProps> = ({ children }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [account, setAccountState] = useState<Parse.Object | null>(null);
  const [isLoadingAccount, setIsLoadingAccount] = useState(false);
  const [onboardingStep, setOnboardingStep] = useState(0);
  const [isNewUser, setIsNewUser] = useState(false);
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);
  const [first500UserFollowedChannels, setFirst500UserFollowedChannels] = useState<Parse.Object[]>([]);
  const [userCreatedChannels, setUserCreatedChannels] = useState<Parse.Object[]>([]);
  const isMobile = Capacitor.getPlatform() === "ios" || Capacitor.getPlatform() === "android";
  const [subscription, setSubscription] = useState<Parse.LiveQuerySubscription | null>(null);

  useApplePushNotifications();

  const setAccount = (newAccount: any) => {
    if (!newAccount) { return }
    setAccountState(newAccount)

    // Do anything here for initial app start
    checkInitialNotifications(newAccount);
  }

  const checkInitialNotifications = async (account: Parse.Object) => {
    const query = new Parse.Query("Notifications");
    query.equalTo("notifiers", account.get("userId"));
    query.descending("createdAt");
    query.limit(25);
    query.find().then((results) => {
      const hasUnread = results.some(notif => notif.get("unread") === true);
      if (hasUnread) {
        setHasUnreadNotifications(true);
      }
    });

    // Set up subscription
    const liveQuerySubscription = await query.subscribe();
    liveQuerySubscription.on('create', (newNotification) => {
      const hasUnread = newNotification.get("unread") === true;
      if (hasUnread) {
        setHasUnreadNotifications(true);
      }
    });
    setSubscription(liveQuerySubscription);
  }

  const refreshAccount = () => {
    const query = new Parse.Query("Account");
    query.equalTo("userId", Parse.User.current()?.id);
    query.first().then((account) => {
      if (!account) { return }
      fetchUserFollowedChannels(account);
      setAccountState(account);
      setIsNewUser(account.get("isOnboarded") === false);
    });
  }

  // Function to update onboardingStep
  const updateOnboardingStep = (step: any) => {
    setOnboardingStep(step);
  };

  const handleLoginSuccess = () => {
    setIsLoadingAccount(true);
    const fetchAccount = async () => {
      const query = new Parse.Query("Account");
      query.equalTo("userId", Parse.User.current()?.id);
      try {
        const result = await query.first();
        if (result) {
          setAccount(result);
          fetchUserFollowedChannels(result);
          fetchUserCreatedChannels(result);
          mixpanel.identify(result.id);
          FirebaseAnalytics.setUserId({ userId: result.id });
        } else {
          setIsNewUser(true)
        }
      } catch (error) {
        console.error("Failed to fetch account: ", error);
      } finally {
        setIsLoadingAccount(false);
      }
    };

    fetchAccount();
  };

  const fetchUserFollowedChannels = async (account: Parse.Object) => {
    const followedChannelsRelation = account.relation("followedChannels");
    const followedChannelsQuery = followedChannelsRelation.query();
    followedChannelsQuery.limit(500);
    followedChannelsQuery.find().then((results) => {
      setFirst500UserFollowedChannels(results);
    });
  }

  const fetchUserCreatedChannels = async (account: Parse.Object) => {
    const query = new Parse.Query("Channel");
    query.equalTo("creator", account);
    query.equalTo("isDeleted", false);
    query.find().then((results) => {
      setUserCreatedChannels(results);
    });
  }

  useEffect(() => {
    const user = Parse.User.current();
    if (user) {
      // Initial fetch for the account and admin status
      handleLoginSuccess();

      const getAdminStatus = async () => {
        const admin = await Parse.Cloud.run("isAdmin");
        setIsAdmin(!!admin); // Ensure admin is a boolean
      };
      getAdminStatus();
    } else {
      return;
    }
  }, []);

  useEffect(() => {
    // Listen for login success event
    window.emitter.on("SEAM_EVENT_LOGIN_SUCCESS", handleLoginSuccess);
    window.emitter.on("SEAM_EVENT_CREATED_ACCOUNT", handleLoginSuccess);
    window.emitter.on("SEAM_EVENT_TOTAL_NOTIFICATIONS", (total) => {
      setHasUnreadNotifications(total > 0);
    });
  }, []);

  return (
    <SeamUserContext.Provider value={{
      isAdmin,
      account,
      setAccount,
      refreshAccount,
      isLoadingAccount,
      onboardingStep,
      isNewUser,
      setOnboardingStep: updateOnboardingStep,
      hasUnreadNotifications,
      first500UserFollowedChannels,
      userCreatedChannels
    }}>
      {children}
    </SeamUserContext.Provider>
  );
};

export const useSeamUser = () => useContext(SeamUserContext);