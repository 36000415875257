import React, { useState, useEffect } from 'react';
import SeamUserItem from '../components/SeamUserItem';
import { useSeamNavigator } from '../Navigation/SeamNavigatorContext';
import FriendRequestButton from '../InternalBlocks/FriendRequestButton';
import { Divider } from '@mui/material';
import Parse from 'parse';
import { useMobile } from '../utils/MobileContext';

const SearchDropdown = ({ searchTerm }) => {
  const [searchResults, setSearchResults] = useState({ users: [], tags: [] });
  const {isMobileApp, isMobile} = useMobile();
  const seamNavigator = useSeamNavigator();

  useEffect(() => {
    const fetchSearchResults = async () => {
      if (searchTerm.trim().length === 0) {
        setSearchResults({ users: [], tags: [] });
        return;
      }
      
      try {
        const results = await Parse.Cloud.run('combinedSearch', { searchTerm });
        setSearchResults(results);
      } catch (error) {
        console.error('Error during search:', error);
        setSearchResults({ users: [], tags: [] });
      }
    };
    fetchSearchResults();
    console.log("search results: ", searchResults);
  }, [searchTerm]);

  const navigateToTag = (tagName) => {
    seamNavigator.navigateTo(`/search/${tagName}`);
  };

  return (
    <div className="flex flex-col items-center h-auto justify-center w-full z-10 bg-white shadow-lg border-t-[2px] border-seam-black/[20%]">
      <div className="w-full h-auto">
        {searchResults.users.length > 0 && (
          <div className={`${(isMobileApp || isMobile) ? "w-auto mx-4" : "w-[66%] mx-auto"} flex flex-col justify-center my-4 items-start`}>
            <h3 className="mb-4">People</h3>
            {searchResults.users.map((user) => (
              <React.Fragment key={user.id}>
                <div className="w-[90%] my-4">
                  <SeamUserItem 
                    avatar={user.get("profilePhoto")}
                    title={<h3 className="pb-1">@{user.get("profileId")}</h3>}
                    subtitle={<h3 className="opacity-40">{user.get("name")}</h3>}
                    link={`user/${user.get("profileId")}`}
                  />
                </div>
              </React.Fragment>
            ))}
          </div>
        )}
        {searchResults.tags.length > 0 && (
          <div className={`${(isMobileApp || isMobile) ? "w-auto mx-4 h-auto" : "w-[66%] mx-auto"} my-4 flex flex-col justify-center items-start`}>
            <h3 className="w-full">Tags</h3>
            {searchResults.tags.map((tag) => (
              <React.Fragment key={tag.tagName}>
                <div className="flex justify-between w-full items-center space-x-4 my-4"> 
                  <h3 className={`${(isMobileApp || isMobile) ? "max-w-[200px] w-auto" : " max-w-[250px] w-auto"} truncate whitespace-nowrap inline-block rounded-[8px] px-2 py-1 hover:bg-gray-100 text-seam-blue bg-seam-green cursor-pointer`} onClick={() => navigateToTag(tag.tagName)}>
                    #{tag.tagName}
                  </h3>
                  <div className="w-auto">
                    <h3 className="text-seam-black opacity-40 whitespace-nowrap w-full">
                      {tag.tagCount} {tag.tagCount === 1 ? 'Post' : 'Posts'}
                    </h3>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
      {searchResults.users.length === 0 && searchResults.tags.length === 0 && searchTerm.trim().length > 0 && (
        <div className="p-2 text-center text-sm text-gray-500">No results found</div>
      )}
    </div>
  );
};

export default SearchDropdown;
