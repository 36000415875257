import LeftBannerNav from "../Navigation/LeftBannerNav";
import { useMobile } from "../utils/MobileContext";
import { useSeamUser } from "../utils/SeamUserContext";

export default function DesktopSidebarWrapper({ children }) {
  const { isMobileApp, isMobile } = useMobile();
  const { account } = useSeamUser();
  if (isMobileApp || isMobile ) return children;

  return (
    <div className="flex flex-row justify-between w-full h-full">
      <div className="w-[158px] h-[100%] bg-transparent flex-none items-center border-r-2 border-black/[5%]">
        {account && <LeftBannerNav />}
      </div>
      {/* center content */}
      <div className="flex justify-center items-center w-full h-full">
        {children}
      </div>
      <div className="w-[156px] bg-transparent flex-none border-l-2 border-seam-black/[5%]"></div>
    </div>
  );
}