import { useSeamUser } from "../utils/SeamUserContext";
import { useSeamNavigator } from "./SeamNavigatorContext";
import { getProcessedProfilePictureURL } from "../components/helpers/ImageTransformers";
import { motion } from "framer-motion";
import { Bell } from "react-feather";
import { ReactComponent as HomeIcon } from "../assets/icons/homeIcon2.svg";
import { Avatar } from "@mui/material";
import { useIonRouter } from "@ionic/react";

// motion div animation settings
const tabVariants = {
  hidden: { scale: 0 },
  visible: { scale: 1 },
};

const LeftBannerNav = () => {
  const { account, hasUnreadNotifications } = useSeamUser();
  const router = useIonRouter();
  const seamNavigator = useSeamNavigator();
  const currentAccountProcessedProfilePhoto = getProcessedProfilePictureURL(
    account?.get("profilePhoto")
  );

  const isNotificationsPage = router.routeInfo.pathname === "/notifications";
  const isHomePage = ["/", "/home"].includes(router.routeInfo.pathname);
  const isProfilePage = [`/user/${account?.get("profileId")}`, `/${account?.get("profileId")}`].includes(router.routeInfo.pathname) ?? false;

  return (
    <div className="p-0 flex flex-col items-center justify-end h-full">
      <div className="bg-black max-w-[60px] max-h-[180px] flex flex-col items-center justify-center rounded-full mb-8 p-1">
        <div
          onClick={() => seamNavigator.navigateTo("/home")}
          style={{ backgroundColor: "transparent" }}
          className="rounded-full bg-seam-black flex justify-center items-center p-[14px]"
        >
          <motion.div
            initial={isHomePage ? "visible" : "hidden"}
            animate={isHomePage ? "visible" : "hidden"}
            variants={tabVariants}
            transition={{ duration: 0.3 }}
            className="absolute bg-white rounded-full h-[50px] w-[50px]"
          />
          <HomeIcon fill={isHomePage ? "black" : "white"} style={{zIndex: 1}} />
        </div>
        <div
          onClick={() => seamNavigator.navigateTo(`/user/${account.get("profileId")}`)}
          style={{ backgroundColor: "transparent" }}
          className="p-[13px] rounded-full bg-seam-black flex justify-center items-center"
        >
          <motion.div
            initial={isProfilePage ? "visible" : "hidden"}
            animate={isProfilePage ? "visible" : "hidden"}
            variants={tabVariants}
            transition={{ duration: 0.3 }}
            className="absolute bg-white rounded-full h-[50px] w-[50px]"
          />
          <Avatar
            src={currentAccountProcessedProfilePhoto}
            className={`w-[22px] h-[22px] border-[2px] ${isProfilePage ? "border-seam-black" : "border-seam-white"}`}
          />
        </div>
        <div
          onClick={() => seamNavigator.navigateTo("/notifications")}
          className="rounded-full bg-black flex relative items-center justify-center p-[14px]"
        >
          <motion.div
            initial={isNotificationsPage ? "visible" : "hidden"}
            animate={isNotificationsPage ? "visible" : "hidden"}
            variants={tabVariants}
            transition={{ duration: 0.3 }}
            className="absolute bg-white rounded-full h-[50px] w-[50px]"
          />
          {hasUnreadNotifications && (
            <div
              key="unread-dot"
              className="bg-seam-pink rounded-full p-1 absolute top-0 mt-1 mb-0 z-2"
            />
          )}
          <Bell size={24} color={isNotificationsPage ? "black" : "white"} style={{zIndex: 1}}  />
        </div>
      </div>
    </div>
  );
};

export default LeftBannerNav;