import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useSeamUser } from './utils/SeamUserContext';
import { IonContent, IonPage } from '@ionic/react';
import Header from './Navigation/Header';
import { Search, UserPlus, UserMinus } from "react-feather";
import SeamUserItem from './components/SeamUserItem';
import { formatPostDate } from './utils/formatPostDate';
import { SubscribeToNotifications, UnsubscribeFromNotifications } from './components/NotificationRegistrationHelpers';
import Parse from 'parse';
import useSearchReducer from './utils/useSearchReducer';
import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import NotifsOff from './assets/icons/NotifsOff.svg';
import NotifsOn from './assets/icons/NotifsOn.svg';
import { useMobile } from './utils/MobileContext';
import { Virtuoso } from 'react-virtuoso';
import DesktopSidebarWrapper from './components/DesktopSidebarWrapper';
import Transactions from './Transactions';
import { motion, useAnimation } from 'framer-motion';
import { LoadingSkeleton } from './components/LoadingSkeleton';
import { Typography } from '@mui/material';

const FeedType = {
  notifications: "notifications",
  transactions: "transactions",
};

const NotificationsPage = () => {
  const { account } = useSeamUser();
  const { isMobile, isMobileApp } = useMobile();
  const controls = useAnimation();
  const [notifications, setNotifications] = useState<any[]>([]);
  const [state, dispatch] = useSearchReducer();
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
  const [isSubscribed, setIsSubscribed] = useState<any>(false);
  const [currentSubscription, setCurrentSubscription] = useState<any>();
  const [friendRequests, setFriendRequests] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(FeedType.notifications);
  const swiperRef = useRef<any>(null);

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      if (!account) return;
      // first check if we have an active firebase messaging token
      try {
        let permission = await FirebaseMessaging.checkPermissions()
        const allowed = permission.receive;
        if (!allowed) {
          return;
        }

        const { token } = await FirebaseMessaging.getToken(isMobileApp ? undefined : { vapidKey: process.env.REACT_APP_VAPID_KEY });
        if (!token) {
          setIsSubscribed(false)
          return;
        }

        if (isMobileApp) {
          const AppleSubscription = Parse.Object.extend("AppleSubscription");
          const query = new Parse.Query(AppleSubscription);
          query.equalTo("token", token);
          const subscription = await query.first();
          if (subscription) {
            setCurrentSubscription(subscription);
            setIsSubscribed(true);
          }
        }
      } catch (error) {
        // likely the user has blocked notifications on web
      }
    };
    fetchSubscriptionStatus();
  }, [account]);

  const removeSubscription = async () => {
    if (currentSubscription === undefined) return;
    await UnsubscribeFromNotifications();
    setCurrentSubscription(undefined);
  };

  useEffect(() => {
    const fetchFriendRequests = async () => {
      if (!account) return;
      try {
        const friendQuery = new Parse.Query("PartialConnection");
        friendQuery.equalTo("recipient", account);
        friendQuery.include("initiator");
        const results = await friendQuery.find();
        if (results.length > 0) {
          setFriendRequests(results);
        }
      } catch (error) {
        console.error("Error fetching friend requests:", error);
      }
    };
    fetchFriendRequests();

    const handleFriendRequestUpdate = () => fetchFriendRequests();
    window.emitter.on('SEAM_EVENT_ACCEPTED_FRIEND_REQUEST', handleFriendRequestUpdate);
    window.emitter.on('SEAM_EVENT_DENIED_FRIEND_REQUEST', handleFriendRequestUpdate);

    return () => {
      window.emitter.off('SEAM_EVENT_ACCEPTED_FRIEND_REQUEST', handleFriendRequestUpdate);
      window.emitter.off('SEAM_EVENT_DENIED_FRIEND_REQUEST', handleFriendRequestUpdate);
    };
  }, [account]);

  useEffect(() => {
    if (!account) return;
    const query = new Parse.Query("Notifications");
    query.equalTo("notifiers", account?.get("userId"));
    query.include("actorAccountId");
    query.limit(25);
    query.descending("createdAt");

    query.find().then((results: any) => {
      setNotifications(results);
      setIsLoading(false);
      getUnreadNotifications(results);
    });

    async function setupSubscription() {
      if (swiperRef.current === true) return;
      const subscription = await query.subscribe();
      subscription.on('create', (object) => {
        const initiatorQuery = new Parse.Query("Account");
        initiatorQuery.equalTo("objectId", object.get("actorAccountId")?.id);
        initiatorQuery.first().then((initiator) => {
          object.get("initiator");
          setNotifications((prevData: any) => [...prevData, object]);
          setUnreadNotificationCount(1);
          window.emitter.emit("SEAM_EVENT_TOTAL_NOTIFICATIONS", unreadNotificationCount + 1);
        });
      });
      swiperRef.current = true;
    }
    if (!swiperRef.current && notifications.length > 0) {
      setupSubscription();
    }
  }, [account]);

  const handleFriendRequestAction = async (requestId: string, action: 'accept' | 'deny') => {
    const actionMap = {
      accept: "acceptConnectionRequest",
      deny: "denyConnectionRequest",
    };
    try {
      await Parse.Cloud.run(actionMap[action], { connectionId: requestId });
      alert(`Friend request ${action}ed.`);
      setFriendRequests((prevRequests: any) => prevRequests.filter((request: any) => request.id !== requestId));
    } catch (error) {
      console.error(`Error ${action}ing friend request:`, error);
    }
  };

  const unreadNotifications = notifications.filter((notif: any) => notif.get("unread") === true);
  const readNotifications = notifications.filter((notif: any) => notif.get("unread") === false);

  const getUnreadNotifications = (notifications: any) => {
    let unreadCount = 0;
    for (var notif of notifications) {
      if (notif.get("unread") === true) unreadCount++;
    }
    setUnreadNotificationCount(unreadCount);
  };

  const renderNotifTitle = (notification: any) => {
    return (
      <h3 className="text-black font-normal font-sans w-full truncate text-base hover:underline">
        {notification.get("description")}
      </h3>
    );
  };

  const renderNotifSubtitle = (notification: any) => {
    return (
      <h4 className="text-gray-500 text-[16px] font-normal font-sans truncate w-full text-center items-center">
        {formatPostDate(notification.get("createdAt"))}
      </h4>
    );
  };

  const UnreadDot = () => (
    <div className="w-2 h-2 bg-[#EE39FB] rounded-full"></div>
  );

  const handleNotifClick = async () => {
    if (!account) return;
    if (isSubscribed) {
      await removeSubscription();
      setCurrentSubscription(undefined);
      setIsSubscribed(false);
    } else {
      const sub = await SubscribeToNotifications(account);
      if (sub) {
        setCurrentSubscription(sub);
        setIsSubscribed(true);
      }
    }
  };

  const toggleSearchBar = () => {
    dispatch({ type: 'TOGGLE_SEARCH_BAR' });
  };

  const handleSearch = (searchString: string) => {
    dispatch({ type: 'UPDATE_SEARCH_STRING', payload: searchString });
  };

  const setNotificationRead = async (notification: any) => {
    notification.set("unread", false);
    notification.save().then(() => {
      setUnreadNotificationCount(unreadNotificationCount - 1);
      window.emitter.emit("SEAM_EVENT_TOTAL_NOTIFICATIONS", unreadNotificationCount - 1);
    });
  };

  const items = useMemo(() => {
    const combinedItems = [];

    if (friendRequests.length > 0) {
      combinedItems.push({ type: 'header', content: 'Friend Requests' });
      combinedItems.push(...friendRequests.map((request: any) => ({ type: 'friendRequest', content: request })));
    }

    if (unreadNotifications.length > 0) {
      combinedItems.push({ type: 'header', content: 'New' });
      combinedItems.push(...unreadNotifications.map((notification: any) => ({ type: 'unreadNotification', content: notification })));
    }

    if (readNotifications.length > 0) {
      combinedItems.push({ type: 'header', content: 'Earlier' });
      combinedItems.push(...readNotifications.map((notification: any) => ({ type: 'readNotification', content: notification })));
    }

    return combinedItems;
  }, [friendRequests, unreadNotifications, readNotifications]);

  const itemContent = (index: any, item: any) => {
    switch (item.type) {
      case 'header':
        return <h3 className="font-normal font-sans p-4 m-0">{item.content}</h3>;
      case 'friendRequest':
        const request = item.content;
        const initiator = request.get("initiator");
        if (!initiator) return null;
        return (
          <div key={request.id} className={`flex ${isMobile ? "flex-col" : "flex-row"} p-4 border-b border-gray-500/20`}>
            <div className="flex flex-col w-full h-full">
              <SeamUserItem
                avatar={initiator.get("profilePhoto")}
                title={<h3 className="text-black font-normal font-sans text-base leading-normal hover:underline">{initiator.get("name")} sent you a friend request</h3>}
                subtitle={<h4 className="font-sans truncate">{formatPostDate(request.createdAt)}</h4>}
                link={`user/${initiator.get("profileId")}`}
                actions={null} // Actions are rendered below
              />
            </div>
            <div className={`flex justify-center w-full h-auto items-center ${isMobile && "mt-4"}`}>
              <div
                className="cursor-pointer border-2 border-[#0051E8]/[5%] bg-[#0051E8] text-[#FEFEFE] px-4 py-2 rounded w-full mr-4 rounded-[30px]"
                onClick={() => handleFriendRequestAction(request.id, 'accept')}
              >
                <div className="text-white flex justify-center space-x-2 leading-loose text-[12px] font-normal">
                  <UserPlus size={24} className="mr-2" />
                  Accept
                </div>
              </div>
              <div
                className="border-2 border-seam-black/[10%] cursor-pointer bg-[#FEFEFE] text-[#101010] px-4 py-2 rounded w-full rounded-[30px]"
                onClick={() => handleFriendRequestAction(request.id, 'deny')}
              >
                <div className="text-[#101010] flex justify-center space-x-2 leading-loose text-[12px] font-normal">
                  <UserMinus size={24} className="mr-2" />
                  Deny
                </div>
              </div>
            </div>
          </div>
        );
      case 'unreadNotification':
      case 'readNotification':
        const notification = item.content;
        if (!notification) return null;
        return (
          <div className="px-4 py-4 border-b border-gray-500/20" onClick={() => { setNotificationRead(notification) }}>
            <SeamUserItem
              avatar={notification?.get("actorAccountId")?.get("profilePhoto")}
              title={renderNotifTitle(notification)}
              subtitle={renderNotifSubtitle(notification)}
              link={notification.get("url")}
              actions={item.type === 'unreadNotification' ? <UnreadDot /> : <></>}
            />
          </div>
        );
      default:
        return null;
    }
  };

  const isEmpty = friendRequests.length === 0 && notifications.length === 0 && !isLoading;

  const EmptyState = () => {
    return (
      <div className="px-6 mx-6 flex flex-col items-center justify-start w-full max-w-[400px] h-full space-y-4 my-6">
        <div className="flex justify-center items-center rounded-full bg-[#efefef] p-6 border-2 border-seam-black/[5%]">
          <h1 className="">🔔</h1>
        </div>
        <h2 className="text-seam-black text-center">No Notifications</h2>
        <h3 className="text-seam-black/[33%] text-center">Activity from other users interacting with your content will show up here.</h3>
      </div>
    );
  };

  const handleTabChange = (newTab: string) => {
    if (newTab === activeTab) return;
    setActiveTab(newTab);
  };  

  return (
    <IonPage id={"notificationsTab"} className="w-full m-auto h-full">
      <Header
        isActivePage={true}
        leftIcon={isSubscribed ? <img src={NotifsOn} alt="Notifications On" className="w-6 h-6 fill-seam-black py-2 px-6 cursor-pointer" /> : <img src={NotifsOff} alt="Notifications Off" className="h-6 w-6 fill-seam-black py-2 px-6 cursor-pointer" />}
        rightIcon={<></>}
        leftAction={handleNotifClick}
        rightAction={() => { }}
        showSearchBar={state.showSearchBar}
        onSearchSubmit={handleSearch}
        scrollToTop={() => { }}
        leftIconColor="seam-green"
        rightIconColor={undefined}
        centerIcon={undefined}
      />
      <IonContent fullscreen={true} scrollY={false}>
        <DesktopSidebarWrapper>
          <div className={`flex flex-col w-full max-w-[720px] ${!isMobileApp && 'h-full'}`}>
          <div className="flex p-4 justify-center w-auto h-auto items-center overflow-hidden hide-scrollbar">
            <div className="flex w-full max-w-[720px] justify-between items-center">
              <button
                className={`py-3 flex flex-1 justify-center items-center space-x-1 relative ${
                  activeTab === FeedType.notifications ? 'text-blue-600' : 'text-gray-400'
                }`}
                onClick={() => handleTabChange(FeedType.notifications)}
              >
                <Typography className="font-sans" variant={isMobile ? 'h4' : 'h3'}>
                  Notifications
                </Typography>
                {activeTab === FeedType.notifications && (
                  <div className="absolute bottom-0 left-0 w-full h-1 bg-blue-600"></div>
                )}
              </button>
              <button
                className={`py-3 flex flex-1 justify-center items-center space-x-1 relative ${
                  activeTab === FeedType.transactions ? 'text-blue-600' : 'text-gray-400'
                }`}
                onClick={() => handleTabChange(FeedType.transactions)}
              >
                <Typography className="font-sans" variant={isMobile ? 'h4' : 'h3'}>
                  Transactions
                </Typography>
                {activeTab === FeedType.transactions && (
                  <div className="absolute bottom-0 left-0 w-full h-1 bg-blue-600"></div>
                )}
              </button>
            </div>
          </div>
            <div className="w-full h-full">
              {activeTab === FeedType.notifications && (
                <div className={`w-full h-full flex flex-col items-center justify-start`}>
                  {isLoading ? (
                    <div className={`w-full h-full flex flex-col`}>
                      {LoadingSkeleton()}
                    </div>
                  ) : isEmpty ? (
                    <EmptyState />
                  ) : (
                    <Virtuoso
                      style={{ height: window.innerHeight - 140, width: '100%' }} // Adjusted height
                      className={`no-scrollbar`}
                      data={items}
                      itemContent={itemContent}
                    />
                  )}
                </div>
              )}
              {activeTab === FeedType.transactions && <div className="w-full h-full flex grow"><Transactions /></div>}
            </div>
          </div>
        </DesktopSidebarWrapper>
      </IonContent>
    </IonPage>
  );
};

export default NotificationsPage;