import ChannelStats from '../components/ChannelStats';
import ImageUploadComponent from '../components/ImageUploadComponent';
import { TagFacesOutlined, } from '@mui/icons-material';
import { Avatar, TextField, Typography, Skeleton, Stack } from '@mui/material';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import { makeStyles } from "@mui/styles";
import React, { useState, useRef } from 'react';
import { useSeamNavigator } from '../Navigation/SeamNavigatorContext';
import { useMobile } from '../utils/MobileContext';

const ChannelFeedHeader = ({ state, dispatch, isChannelCreator, handleImageUpload, loading, loadedPosts }) => {
  const { isMobile } = useMobile();
  const currentChannel = state.currentChannel;
  const fontColor = state.fontColor;
  const [isEditingName, setIsEditingName] = useState(false);
  const [localChannelName, setLocalChannelName] = useState(state.channelName || "Collection Name");
  const [error, setError] = useState(false); 
  const inputRef = useRef(null); 
  const channelName = state.channelName || "Collection Name";
  const channelPicture = state.channelPicture || null;
  const selectedEmoji = state.selectedEmoji;
  const [emojiPickerVisible, setEmojiPickerVisible] = useState(false);

  const useStyles = makeStyles({
    input: {
      '& .MuiInputBase-input': {
        color: fontColor, 
      },
      '& .MuiInputBase-input::placeholder': {
        color: fontColor, 
        opacity: 0.5,
      },
    },
  });

  const classes = useStyles();

  const handleEmojiSelect = async (emoji) => {
    const selected = emoji.native;
    dispatch({
      type: "SET_CHANNEL_DATA",
      payload: { ...state, selectedEmoji: selected }
    });
    setEmojiPickerVisible(false);
  
    if (currentChannel) {
      currentChannel.set("emoji", selected);
      try {
        await currentChannel.save();
      } catch (error) {
        console.error("Failed to save emoji:", error);
      }
    }
  };

  const handleNameChange = (e) => {
    const newName = e.target.value;

    if (newName.trim() !== "") {
      setError(false);
    }

    // Alphanumeric validation
    const alphanumericRegex = /^[a-zA-Z0-9\s]*$/;
    if (!alphanumericRegex.test(newName)) {
      return;
    }

    setLocalChannelName(newName);
  };

  const handleSaveName = async () => {
    const currentChannel = state.currentChannel;

    if (!localChannelName || localChannelName.trim() === "") {
      setError(true);
      return;
    }

    if (localChannelName === currentChannel.get("name")) {
      setIsEditingName(false);
      return;
    }

    try {
      currentChannel.set("name", localChannelName);
      await currentChannel.save();
      dispatch({
        type: "SET_CHANNEL_DATA",
        payload: { channel: currentChannel, channelName: localChannelName }
      });
    } catch (error) {
      console.error("Failed to save channel name:", error);
    } finally {
      setIsEditingName(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSaveName();
    }
  };

  const handleBlur = () => {
    handleSaveName();
  };

  const ChannelNameInput = () => {
    return (
      <>
          <div
            className="flex items-center justify-center w-auto h-auto max-w-full cursor-pointer"
          >
            <Typography variant="h2" className={`text-seam-${fontColor} ml-2 w-full text-center truncate`}>
              {localChannelName || "Collection Name"}
            </Typography>
          </div>
      </>
    );
  };

  const seamNavigator = useSeamNavigator();

  const handleModalOpen = () => {
    dispatch({
      type: "TOGGLE_MODAL", 
      payload: true,
    });
  }

  const ChannelFeedHeaderSkeleton = () => {
    return (
      <div className="w-full h-auto my-6">
        <div className="flex flex-col items-center space-y-6 justify-between w-full h-auto">
          <Skeleton variant="rectangular" width={90} height={90} className="rounded-[8px]" />
  
          <Stack direction="row" spacing={2} className="w-full max-w-[66%] items-center justify-center">
            <Skeleton variant="circular" width={30} height={30} />
            <Skeleton variant="text" width={150} height={40} />
          </Stack>
  
          <Skeleton variant="rectangular" width={200} height={40} />
  
          <Stack direction="row" spacing={1} alignItems="center">
            <Skeleton variant="text" width={80} height={20} />
            <Skeleton variant="circular" width={30} height={30} />
            <Skeleton variant="text" width={100} height={20} />
          </Stack>
        </div>
      </div>
    );
  };

  if (loading) {
    return <ChannelFeedHeaderSkeleton />;
  }

  return (
    <>
      <div className="w-full h-auto my-6">
        <div className="flex flex-col items-center space-y-6 justify-between w-full h-auto">
          {/* Channel pic customization */}
          <div className="w-[90px] h-[90px] rounded-[8px]">
            {!isChannelCreator ? (
              <img
                src={channelPicture || null}
                className={`w-full h-full object-cover border-[1px] border-seam-${fontColor} rounded-[8px]`}
              />
            ) : (
              <ImageUploadComponent
                fileTypes="image/*"
                label="Upload"
                onUpdate={handleImageUpload}
                backgroundImageUrl={channelPicture}
              />
            )}
          </div>

          {/* Emoji / channel name customization */}
          <div className="w-full max-w-[66%] relative flex flex-row items-center justify-center" onClick={() => {
            if (isChannelCreator) {
              dispatch({
                type: "TOGGLE_MODAL",
                payload: true,
              });
            }
          }}>
            <div
              className="rounded-full mr-2 flex items-center justify-center w-auto h-auto"
            >
            {selectedEmoji ? (
              <span style={{ fontSize: '24px' }}>{selectedEmoji}</span>
            ) : (
              <TagFacesOutlined className={`w-[10px] h-[10px] fill-seam-${fontColor}`} />
            )}
            </div>
            <ChannelNameInput />
          </div>

          <ChannelStats
            handleModalOpen={handleModalOpen}
            subscribers={currentChannel.get("numSubscribers")}
            posts={currentChannel.get("numPosts")}
            fontColor={fontColor}
          />

          <span className='flex flex-row items-center text-xs' onClick={() => { seamNavigator.navigateTo("/user/" + currentChannel.get("creator").get("username"), currentChannel.get("creator")) }}>
            <Typography variant="h4" className={`text-seam-${fontColor}`}>Curated By: </Typography>
            <Avatar src={currentChannel.get("creator").get("profilePhoto")} className="w-6 h-6 mx-2" />
            <Typography variant="h4" className={`text-seam-${fontColor}`}>{currentChannel.get("creator").get("username")}</Typography>
          </span>

          {/* Empty state message when no posts are available */}
          {isChannelCreator && loadedPosts?.length === 0 && !loading && (
            <div className="w-full h-full flex flex-col items-center justify-center text-gray-500 py-6 space-y-12">
              <h3 className="text-seam-pink">Post stuff to your Collection here!</h3>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ChannelFeedHeader;