import { useState } from 'react';
import { Divider, Stack, Theme, Button, Tooltip, Modal, Box, Typography } from "@mui/material"
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { createFriendRequest } from '../utils/ConnectionRequestNetworkHelpers';
import Parse from 'parse';
import * as Sentry from "@sentry/react";
import { useSeamUser } from '../utils/SeamUserContext';
import { useSeamNavigator } from '../Navigation/SeamNavigatorContext';

export default function InternalConnections(model, theme, creatorAccount) {
  const [isLoading, setIsLoading] = useState(false)
  const { account } = useSeamUser();

  const seamNavigator = useSeamNavigator();
  const isAuthenticated = Parse.User.current() != undefined

  const [connections, setConnections] = useState([])
  const [numConnections, setNumConnections] = useState(0)
  const [friendConnection, setFriendConnection] = useState(undefined)
  const [pendingConnection, setPendingConnection] = useState(undefined)

  const [optimisticRequest, setOptimisticRequest] = useState(false)
  const [optimisticBreakup, setOptimisticBreakup] = useState(false)

  const [isConnectionsModalOpen, setIsConnectionsModalOpen] = useState(false);

  let primaryColor = theme.palette.primary.main
  let secondaryColor = theme.palette.secondary.main
  let teritaryColor = theme.palette.info.main

  const isSelfProfile = account && creatorAccount && (account.id == creatorAccount.id)

  useEffect(() => {
    if (creatorAccount) {
      setIsLoading(true)
      const connectionsRelation = creatorAccount.relation("Connections")
      const query = connectionsRelation.query()
      query.limit(100).find().then(function (results) {
        setConnections(results)
        if (results.length == 100) {
          //too many friends, fire a query to find out how many they actually have
          query.count().then((number) => {
            setNumConnections(number)
          });
        } else {
          setNumConnections(results.length)
        }
        setIsLoading(false)
      })
    }
  }, [creatorAccount]);

  // check to see if the two accounts are connections already
  useEffect(() => {
    if (creatorAccount != undefined && account != undefined) {
      const connectionsRelation = creatorAccount.relation("Connections")
      const query = connectionsRelation.query()
      query.equalTo("objectId", account.id)
      query.first().then(function (result) {
        setFriendConnection(result)
      })
        .catch(function (error) {
          console.log(error)
          Sentry.captureException(error);
        })
    }
  }, [creatorAccount]);

  useEffect(() => {
    // check the pending connections to make sure there isn't an outstanding request to action
    if (creatorAccount != undefined) {
      // if the viewer is the recipient of the card creator's pending friend request (accept button)
      const recipientQuery = new Parse.Query("PartialConnection");
      recipientQuery.equalTo("recipient", account);
      recipientQuery.equalTo("initiator", creatorAccount);

      // if the viewer requested the card creator account (dismiss button)
      const initiatorQuery = new Parse.Query("PartialConnection");
      initiatorQuery.equalTo("initiator", account);
      initiatorQuery.equalTo("recipient", creatorAccount);
      const mainQuery = Parse.Query.or(recipientQuery, initiatorQuery)
      mainQuery.first().then((result) => {
        setPendingConnection(result)
      })
    }
  }, [creatorAccount]);

  useEffect(() => {
    const handleUpdatedFriendRequest = (event) => {
      if (pendingConnection && pendingConnection.id === event.connectionId) {
        // Check the status and update the state accordingly
        if (event.status === 'accepted') {
          setFriendConnection(/* updated friend connection object */);
          setPendingConnection(undefined);
        } else if (event.status === 'denied') {
          setPendingConnection(undefined);
        }
      }
    };

    window.emitter.on('SEAM_EVENT_ACCEPTED_FRIEND_REQUEST', handleUpdatedFriendRequest);
    window.emitter.on('SEAM_EVENT_DENIED_FRIEND_REQUEST', handleUpdatedFriendRequest);

    return () => {
      window.emitter.off('SEAM_EVENT_ACCEPTED_FRIEND_REQUEST', handleUpdatedFriendRequest);
      window.emitter.off('SEAM_EVENT_DENIED_FRIEND_REQUEST', handleUpdatedFriendRequest);
    };
  }, [pendingConnection]);

  const addConnection = () => {
    if (window.confirm("Do you want to send a friend request to " + creatorAccount.get("name") + "?")) {
      // Add to partial connections table
      createFriendRequest(account, creatorAccount);
      setOptimisticRequest(true)
    }
  }

  const deleteConnection = () => {
    if (window.confirm("Are you sure you want to break up with " + creatorAccount.get("name") + "?")) {
      setOptimisticBreakup(true)
      Parse.Cloud.run("removeConnection", { account1: account.id, account2: creatorAccount.id })
    }
  }

  const acceptFriendRequest = () => {
    Parse.Cloud.run("acceptConnectionRequest", { connectionId: pendingConnection.id }).then((result) => {
      alert("Awesome - you're friends with " + creatorAccount.get("name") + "!")
      setPendingConnection(undefined)
      setFriendConnection(result)
      window.emitter.emit("SEAM_EVENT_ACCEPTED_FRIEND_REQUEST", { connectionId: pendingConnection.id, status: 'accepted' });
    })
  }

  const denyFriendRequest = () => {
    if (window.confirm("Do you want to cancel the connection to " + creatorAccount.get("name") + "?")) {
      Parse.Cloud.run("denyConnectionRequest", { connectionId: pendingConnection.id }).then((result) => {
        setPendingConnection(undefined)
        window.emitter.emit("SEAM_EVENT_DENIED_FRIEND_REQUEST", { connectionId: pendingConnection.id, status: 'denied' });
      })
    }
  }

  const shouldShowFriendButton = !isSelfProfile
  const isFriends = optimisticBreakup ? false : friendConnection != undefined
  const isOutstanding = optimisticRequest || (pendingConnection && pendingConnection.get("initiator").id == account?.id)
  const isPending = (pendingConnection && pendingConnection.get("recipient").id == account?.id)
  let friendsLabel = isFriends ? "Remove" : "Add"
  if (isPending) { friendsLabel = "Accept" }
  if (isOutstanding) { friendsLabel = "Cancel" }
  let friendsIcon = isFriends ? <PersonRemoveIcon /> : <PersonAddIcon />
  if (isPending) { friendsIcon = <PersonAddIcon /> }
  if (isOutstanding) { friendsIcon = <PersonRemoveIcon /> }

  const onTap = () => {
    // if the person tapping add friend isn't logged into Seam yet, send them to the homepage (with a referral)
    if (!isAuthenticated) {
      const url = '/?ref=' + creatorAccount?.get('referralCode');
      seamNavigator.navigateTo(url)
      return
    }

    if (account === undefined) {
      alert("You haven't created an account yet! Try logging out and logging back in again.");
      return
    }

    if (isOutstanding) {
      denyFriendRequest()
    } else if (isPending) {
      acceptFriendRequest()
    } else if (isFriends) {
      deleteConnection()
    } else {
      addConnection()
    }
  }

  const emptyStateComponent = () => {
    const emptyText = "No friends yet :("
    return (
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", width: "100%", height: 'calc(100% - 60px)' }}>
        <h3 style={{ color: teritaryColor }}>{emptyText}</h3>
      </div>
    )
  }

  const tapAction = (connection) => {
    const url = "/" + connection.get("profileId")
    seamNavigator.navigateTo(url)
  }

  const getProcessedProfilePictureURL = (rawProfileURL, connection) => {
    if (rawProfileURL) {
      const eligibleForResize = !rawProfileURL.includes(".gif")
      let imageURLRaw = rawProfileURL + "?w=320&h=320"
      return eligibleForResize ? imageURLRaw.replace('raw', 'image') : rawProfileURL;
    } else {
      // console.error("This user (" + JSON.stringify(connection) + ") has a undefied profile pic (" + rawProfileURL + ")");
    }
  }

  function inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  const isEmbedded = inIframe()
  // for pixels and embedded usecases, do not scroll and have a see more link back into Seam
  const shouldScroll = !isEmbedded
  const scrollAttribute = shouldScroll ? "scroll" : "hidden"
  const needsPlaceholders = connections.length < 8
  const isEmpty = connections.length == 0 && !isLoading
  let bg = secondaryColor + 'e6'

  return (
    <Stack direction={"column"} style={{ height: "100%", width: "100%" }} sx={{ backgroundColor: bg }} spacing={"16px"}>
      <div style={{ backgroundColor: secondaryColor }}>
        <Stack direction={"row"} style={{ justifyContent: "space-between", margin: 16 }}>
          <h2 style={{ color: teritaryColor }}>{"[" + numConnections + "]" + " Friends"}</h2>
          {shouldShowFriendButton &&
            <Button
              sx={{ color: teritaryColor, borderRadius: 0 }}
              style={{ border: "1px solid" }}
              size="large"
              onClick={() => { onTap() }}
              variant="outlined"
              startIcon={friendsIcon}> {friendsLabel}
            </Button>}
        </Stack>
        <Divider sx={{ backgroundColor: teritaryColor, height: '1px' }} />
      </div>
      <div style={{ position: "relative", height: '100%', width: "100%" }}>
        {isEmpty && emptyStateComponent()}
        <ImageList cols={4}
          style={{ maxHeight: '100%', position: 'absolute', overflow: scrollAttribute }}
          sx={{ '&::-webkit-scrollbar': { display: 'none' }, padding: "8px" }}>
          {connections?.map((connection) => (
            <Tooltip key={connection.id} title={<h4 style={{ color: "white" }}>
              {connection.get("name")}</h4>}>
              <ImageListItem key={connection.id} onClick={() => { tapAction(connection) }}
                style={{ cursor: "pointer", }}>
                <img
                  src={getProcessedProfilePictureURL(connection?.get("profilePhoto"), connection)}
                  alt={connection.get("name")}
                  loading="lazy"
                  style={{ aspectRatio: 1 }}
                />
              </ImageListItem>
            </Tooltip>
          ))}
          {needsPlaceholders && Array(8 - connections.length)
            .fill()
            .map((_, i) => (
              <ImageListItem key={i}>
                <div style={{
                  backgroundColor: '#cccccc',
                  aspectRatio: 1
                }} />
              </ImageListItem>
            ))}
        </ImageList>
      </div>
    </Stack>
  );
}