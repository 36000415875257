import React from 'react';
import { MoreHoriz } from '@mui/icons-material';

const ChannelStats = ({ handleModalOpen, subscribers, posts, setModalOpen, fontColor }) => {  
  return (
    <div className="flex flex-col items-center justify-center w-full h-auto space-y-2">
      <span className="flex flex-row items-center justify-center">
        {subscribers > 1 && (
          <>
            <span className="flex flex-row items-center justify-center">
              <h4 className={`text-seam-${fontColor}`}>{subscribers}</h4>&nbsp;
              <h4 className={`text-seam-${fontColor}/50`}>Followers</h4>
            </span>
            <h4 className={`w-[18px] h-4 text-center text-${fontColor}`}>•</h4>
          </>
        )}
        <span className="flex flex-row items-center justify-center">
          <h4 className={`text-seam-${fontColor}`}>{posts}</h4>&nbsp;
          <h4 className={`text-seam-${fontColor}/50`}>Posts</h4>
        </span>
        <div
          onClick={() => handleModalOpen()}
          className="rounded-full ml-4 bg-seam-white flex items-center justify-center border border-seam-gray w-[30px] h-[30px]"
        >
          <MoreHoriz className="w-[10px] h-[10px] fill-seam-black" />
        </div>
      </span>
    </div>
  );
};

export default ChannelStats;