import React, { useState, useEffect } from "react";
import { useMobile } from "../utils/MobileContext";
import { Avatar } from "@mui/material";
import { usePrivy } from "@privy-io/react-auth";
import { useHistory } from "react-router";
import { useSeamNavigator } from "../Navigation/SeamNavigatorContext";
import { BadgeTypes } from "../Badges/BadgeTypes";
import { getProcessedProfilePictureURL } from "../components/helpers/ImageTransformers";
import Parse from 'parse';

function ProfilePageHeader({ creatorAccount, profileCustomizations }) {
  const { logout, user } = usePrivy();
  const { isMobile, isMobileApp } = useMobile();
  const history = useHistory();
  const seamNavigator = useSeamNavigator();
  const [bio, setBio] = useState(creatorAccount.get("bio"));
  const profilePicture = getProcessedProfilePictureURL(creatorAccount.get("profilePhoto"));
  const [badges, setBadges] = useState(creatorAccount.get("badges"));
  const [totalSubscribers, setTotalSubscribers] = useState(0);
  const [totalPosts, setTotalPosts] = useState(0);
  const [collectionsCreated, setCollectionsCreated] = useState(0);
  const [collectionsFollowed, setCollectionsFollowed] = useState(0);

  // Fetch data from channels and calculate subscribers, posts, and collections created based on creatorAccount
  useEffect(() => {
    const fetchChannelData = async () => {
      const channelQuery = new Parse.Query("Channel");
      channelQuery.equalTo("creator", creatorAccount); 
      channelQuery.equalTo("isDeleted", false);
      const channels = await channelQuery.find();

      // Calculate total subscribers and total posts
      const totalSubs = channels.reduce((sum, channel) => sum + (channel.get("numSubscribers") || 0), 0);
      const totalNumPosts = channels.reduce((sum, channel) => sum + (channel.get("numPosts") || 0), 0);

      // Update state with the calculated values
      setTotalSubscribers(totalSubs);
      setTotalPosts(totalNumPosts);
      setCollectionsCreated(channels.length);
    };

    const fetchFollowedChannels = async () => {
      const relation = creatorAccount.relation("followedChannels"); 
      const query = relation.query();
      const followedChannels = await query.count(); 
      setCollectionsFollowed(followedChannels);
    };

    if (creatorAccount) {
      fetchChannelData();
      fetchFollowedChannels();
    }
  }, [creatorAccount, profilePicture]); 

  const renderBadges = () => {
    return badges?.map((badgeEarned, index) => {
      const badgeType = BadgeTypes[badgeEarned.type];
      if (!badgeType) return null; // Skip if the badge type is not found

      return (
        <img
          key={index}
          src={badgeType.imageURL}
          alt={badgeType.displayTitle}
          title={badgeType.displayTitle} 
          className="w-[24px] h-[24px] mx-1" 
        />
      );
    });
  };

  const textColor = profileCustomizations?.get("fontColor") || "black";
  return (
    <div className={`flex flex-col items-center space-y-6 justify-between w-full h-auto`} style={{color: textColor}}>
      {/* Avatar and username */}
      <Avatar
        src={profilePicture}
        alt="Profile"
        className={`w-[120px] h-[120px] rounded-full border-2 border-white`}
      />
      <h1 className="leading-tight truncate max-w-xs">
        @{creatorAccount.get("profileId")}
      </h1>

      {/* Stats Section */}
      <div className="flex flex-col items-center justify-center w-full h-auto">
        <div style={{color: textColor }} className="flex flex-row items-center justify-center space-x-2 pl-2">
          <span className="flex flex-row items-center justify-center" onClick={() => {seamNavigator.navigateTo('/friends/' + creatorAccount.get("profileId", creatorAccount))}}>
            <h4>
              {creatorAccount.get("friendCount")}
            </h4>
            &nbsp;
            <h4 style={{ opacity: '50%' }}>
              Friends
            </h4>
          </span>
          <h4 className="w-[18px] h-4 text-center">•</h4>
          <span className="flex flex-row items-center justify-center">
            <h4>
              {totalSubscribers}
            </h4>
            &nbsp;
            <h4 style={{ opacity: '50%' }}>
              Following
            </h4>
          </span>
          <h4 className="w-[18px] h-4 text-center">•</h4>
          <span className="flex flex-row items-center justify-center">
            <h4>
              {totalPosts}
            </h4>
            &nbsp;
            <h4 style={{ opacity: '50%' }}>
              Posts
            </h4>
          </span>
        </div>

        <span className="flex flex-row items-center justify-center space-x-2">
          <span className="flex flex-row items-center justify-center">
            <h4>
              {collectionsCreated}
            </h4>
            &nbsp;
            <h4 style={{ opacity: '50%' }}>
              Collections Made
            </h4>
          </span>
          <h4 className="w-[18px] h-4 text-center">•</h4>
          <span className="flex flex-row items-center justify-center">
            <h4>
              {collectionsFollowed}
            </h4>
            &nbsp;
            <h4 style={{ opacity: '50%' }}>
              Collections Followed
            </h4>
          </span>
        </span>
      </div>

      {/* Badge Bar */}
      {badges && badges.length > 0 && (
      <div onClick={() => seamNavigator.navigateTo("/awards/" + creatorAccount.get("profileId"))} className="w-auto py-1 px-2 h-auto max-h-[24px] flex flex-row items-center justify-center rounded-[24px]">
        {renderBadges()}
      </div>
      )}
    </div>
  );
}

export default ProfilePageHeader;