import betaBadge from "./assets/betaBadge.png";
import bugBadge from "./assets/bugBadge.png";
import builderBadge from "./assets/builderBadge.png";
import powerBadge from "./assets/powerBadge.png";
import ogBadge from "./assets/ogBadge.png";
import MiniappParticip_2024 from './assets/2024_miniapp_chal_particip.png';
import MiniappFirst_2024 from './assets/2024_miniapp_chal_first.png';
import MiniappCreative_2024 from './assets/2024_miniapp_chal_creative.png';
import MiniappDesign_2024 from './assets/2024_miniapp_chal_design.png';
import MiniappQuality_2024 from './assets/2024_miniapp_chal_quality.png';
import MiniappSecond_2024 from './assets/2024_miniapp_chal_second.png';

export type BadgeType = {
  name: string
  displayTitle: string
  description: string
  imageURL: string
}

export type BadgeEarned = {
  date: Date
  type: string // BadgeType.name
}

export const BadgeTypes: { [key: string]: BadgeType } = {
  beta: {
    name: "beta",
    displayTitle: "Seam Beta",
    description: "Join Seam before March 2024",
    imageURL: betaBadge,
  },
  miniappBuilder: {
    name: "miniappBuilder",
    displayTitle: "Miniapp Builder",
    description: "Build a Seam Miniapp",
    imageURL: builderBadge,
  },
  exterminator: {
    name: "exterminator",
    displayTitle: "Bug Basher",
    description: "Report a bug",
    imageURL: bugBadge,
  },
  og: {
    name: "og",
    displayTitle: "OG 🇺🇸🇨🇦",
    description: "Join Seam's iOS launch in the US and Canada",
    imageURL: ogBadge,
  },
  powerUser: {
    name: "powerUser",
    displayTitle: "Power User",
    description: "Earn the recognition of the Seam team",
    imageURL: powerBadge,
  },
  MiniappParticip_2024: {
    name: "miniappParticip_2024",
    displayTitle: "2024 Miniapp Challenge Participant",
    description: "Submitted a miniapp in Seam's summer 2024 Miniapp Challenege",
    imageURL: MiniappParticip_2024
  },
  MiniappFirst_2024: {
    name: "miniappFirst_2024",
    displayTitle: "2024 Miniapp Challenge First Place",
    description: "Submitted a miniapp that won first place in Seam's summer 2024 Miniapp Challenge",
    imageURL: MiniappFirst_2024
  },
  MiniappSecond_2024: {
    name: "miniappSecond_2024",
    displayTitle: "2024 Miniapp Challenge Second Place", 
    description: "Submitted a miniapp that won second place in Seam's summer 2024 Miniapp Challenge",
    imageURL: MiniappSecond_2024
  },
  MiniappQuality_2024: {
    name: "miniappQuality_2024",
    displayTitle: "2024 Miniapp Challenge Best Quality",
    description: "Submitted a miniapp that won Best Quality in Seam's summer 2024 Miniapp Challenge",
    imageURL: MiniappQuality_2024
  },
  MiniappDesign_2024: {
    name: "miniappDesign_2024",
    displayTitle: "2024 Miniapp Challenege Best Design", 
    description: "Submitted a miniapp that won Best Design in Seam's summer 2024 Miniapp Challenge",
    imageURL: MiniappDesign_2024
  },
  MiniappCreative_2024: {
    name: "miniappCreative_2024",
    displayTitle: "2024 Miniapp Challenge Most Creative", 
    description: "Submitted a miniapp that won Most Creative in Seam's summer 2024 Miniapp Challenge", 
    imageURL: MiniappCreative_2024
  }
};