import { getProcessedProfilePictureURL } from "./helpers/ImageTransformers";
import { useSeamNavigator } from "../Navigation/SeamNavigatorContext";
import { Avatar } from "@mui/material";

const SeamUserItem = ({ avatar, title, subtitle, link, actions }) => {
  const processedAvatar = getProcessedProfilePictureURL(avatar);
  const seamNavigator = useSeamNavigator();

  return (
    <div className="flex items-center justify-between w-full">
      <div className="flex items-between justify-center w-full">
        <Avatar src={processedAvatar} className="w-10 h-10" />
        <div className="flex flex-col ml-4 items-start justify-between flex-grow min-w-0">
          <div
            onClick={() => {
              seamNavigator.navigateTo(link);
            }}
            className="w-full flex h-[24px] truncate cursor-pointer hover:underline"
          >
            {title}
          </div>
          <div className="text-gray-600 h-[16px] truncate">
            {subtitle}
          </div>
        </div>
        {actions && <div className="flex items-center ml-4">{actions}</div>}
      </div>
    </div>
  );
};

export default SeamUserItem;
