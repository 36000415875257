import React, { useState, useEffect, useReducer } from 'react';
import Parse from 'parse';
import { IonContent, IonPage } from "@ionic/react";
import { ChevronLeft, PersonAddAlt1 } from "@mui/icons-material";
import { useSeamUser } from "../utils/SeamUserContext";
import Header from "../Navigation/Header";
import SeamComposer from "../Composer/SeamComposer";
import FeedItem from "../Discover/FeedItem";
import { Virtuoso } from "react-virtuoso";
import SeamChannelRepository from './SeamChannelRepository';
import DesktopSidebarWrapper from '../components/DesktopSidebarWrapper';
import { useSeamNavigator } from '../Navigation/SeamNavigatorContext';
import CollectionArrow from "../assets/icons/collectionArrow.png";
import { useMobile } from '../utils/MobileContext';
import ChannelFeedHeader from './ChannelFeedHeader';
import { LoadingSkeleton } from '../components/LoadingSkeleton';
import { useHistory } from 'react-router';
import { Typography } from '@mui/material';
import ChannelPageModal from './ChannelPageModal';

const POSTS_PER_PAGE = 10;

const ChannelPage = ({ channelId }) => {
  const { contextObject, seamNavigator } = useSeamNavigator();
  const initialChannel = contextObject?.className === "Channel" ? contextObject : undefined;
  const initialState = {
    currentChannel: initialChannel,
    channelPicture: initialChannel?.get("headerImage"),
    channelName: initialChannel?.get("name"),
    fontColor: initialChannel?.get("fontColor"),
    selectedEmoji: initialChannel?.get("emoji"),
    color1: initialChannel?.get("color1"),
    color2: initialChannel?.get("color2"),
    isFollowing: false,
    loading: false,
    loadingChannelData: false,
    hasMore: true,
    loadedPosts: [],
    modalOpen: false,
  };

  const channelReducer = (state, action) => {
    switch (action.type) {
      case "SET_CHANNEL_DATA":
        return {
          ...state,
          currentChannel: action.payload.channel || state.currentChannel,
          channelPicture: action.payload.channelPicture || state.channelPicture,
          channelName: action.payload.channelName || state.channelName,
          fontColor: action.payload.fontColor || state.fontColor,
          selectedEmoji: action.payload.selectedEmoji || state.selectedEmoji,
          color1: action.payload.color1 || state.color1,
          color2: action.payload.color2 || state.color2,
          loadingChannelData: false,
        };
      case "SET_FOLLOW_STATUS":
        return {
          ...state,
          isFollowing: action.payload,
        };
      case "SET_LOADING":
        return {
          ...state,
          loading: action.payload,
        };
      case "SET_CHANNEL_LOADING":
        return {
          ...state,
          loadingChannelData: action.payload,
        };
      case "SET_LOADED_POSTS":
        return {
          ...state,
          loadedPosts: [...state.loadedPosts, ...action.payload],
          loading: false,
          hasMore: action.payload.length === POSTS_PER_PAGE,
        };
      case "SET_OPTIMISTIC_POST":
        return {
          ...state,
          loadedPosts: [...action.payload, ...state.loadedPosts],
        };
      case "TOGGLE_MODAL":
        return {
          ...state,
          modalOpen: typeof action.payload === 'boolean' ? action.payload : !state.modalOpen,
        };
      default:
        return state;
    }
  };

  const { account, refreshAccount } = useSeamUser();
  const { isMobile, isMobileApp } = useMobile();
  const history = useHistory();
  const [state, dispatch] = useReducer(channelReducer, initialState);

  const isChannelCreator = state.currentChannel?.get("creator").id === account?.id;

  const fetchChannelData = async () => {
    if (state.currentChannel) return;
    dispatch({ type: "SET_CHANNEL_LOADING", payload: true });
    const Channel = Parse.Object.extend("Channel");
    const query = new Parse.Query(Channel);
    query.equalTo("objectId", channelId);
    query.include("creator");

    try {
      const channel = await query.first();
      if (channel) {
        const channelPicture = channel.get("headerImage");
        const channelName = channel.get("name");
        const fontColor = channel.get("fontColor");
        const selectedEmoji = channel.get("emoji");
        const color1 = channel.get("color1") || '#FFFFFF';
        const color2 = channel.get("color2") || '#FFFFFF';

        dispatch({
          type: "SET_CHANNEL_DATA",
          payload: {
            ...state,
            channel: channel,
            channelPicture: channelPicture,
            channelName: channelName,
            fontColor: fontColor,
            selectedEmoji: selectedEmoji,
            color1: color1,
            color2: color2,
          }
        });
      }
    } catch (error) {
      console.error("Error fetching channel: ", error);
      dispatch({ type: "SET_CHANNEL_LOADING", payload: false });
    }
  };

  const fetchFollowingStatus = async () => {
    if (!account) return;
    // Check if the user is already following the channel
    const followedChannelsRelation = account.relation("followedChannels");
    const followedQuery = followedChannelsRelation.query();
    followedQuery.equalTo("objectId", channelId);
    const isFollowingChannel = await followedQuery.first();

    dispatch({
      type: "SET_FOLLOW_STATUS",
      payload: !!isFollowingChannel,
    });
  };

  useEffect(() => {
    const handlePostDelete = (postId) => {
      dispatch({
        type: "SET_LOADED_POSTS",
        payload: state.loadedPosts.filter(postInChannel => postInChannel.get("post").id !== postId),
      });
    };

    window.emitter.on("SEAM_EVENT_DELETE_POST", handlePostDelete);

    return () => {
      window.emitter.off("SEAM_EVENT_DELETE_POST", handlePostDelete);
    };
  }, []);

  useEffect(() => {
    fetchChannelData();
    fetchFollowingStatus();
  }, [channelId, account]);

  const handlePostCompletion = async (newPostInChannel) => {
    try {
      console.log("success posting to collection", newPostInChannel);
      dispatch({
        type: "SET_OPTIMISTIC_POST",
        payload: [newPostInChannel],
      });

      const toastContent = (
        <div className="flex items-center justify-between h-auto w-auto">
          <h4 className="text-[#FEFEFE]/70 mr-2">You've added a post to</h4>
          <div
            onClick={() => seamNavigator.navigateTo(`/collection/${state.currentChannel.id}`, state.currentChannel)}
            className="flex flex-row items-center justify-center cursor-pointer w-auto max-w-[50%] truncate"
          >
            <span className="no-underline">{state.currentChannel.get("emoji")}</span>
            <h4 className="underline truncate w-full">{state.currentChannel.get("name")}</h4>
          </div>
        </div>
      );

      window.emitter.emit("SEAM_EVENT_ADDED_TO_COLLECTION", toastContent);
    } catch (error) {
      console.error("Error optimistically updating with new savedPostInChannel", error);
    }
  };

  const handleImageUpload = (url) => {
    if (state.currentChannel) {
      try {
        state.currentChannel.set("headerImage", url);
        state.currentChannel.save()
          .then(() => {
            dispatch({
              type: "SET_CHANNEL_DATA",
              payload: { ...state, channel: state.currentChannel, channelPicture: url },
            });
          })
          .catch((error) => {
            console.error("Failed to save channel image:", error);
          });
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }
  };

  const handleFollow = async () => {
    try {
      await SeamChannelRepository.subscribeToChannel(state.currentChannel, account, (finalAccount) => {
        refreshAccount()
        dispatch({
          type: "SET_FOLLOW_STATUS",
          payload: true,
        });
      }, (error) => {
        console.error("Error subscribing to channel:", error);
      });
    } catch (error) {
      console.error("Subscription error:", error);
    }
  };

  const handleUnfollow = async () => {
    try {
      await SeamChannelRepository.unsubscribeToChannel(state.currentChannel, account, (finalAccount) => {
        refreshAccount()
        dispatch({
          type: "SET_FOLLOW_STATUS",
          payload: false,
        });
      }, (error) => {
        console.error("Error unsubscribing from channel:", error);
      });
    } catch (error) {
      console.error("Unsubscription error:", error);
    }
  };

  async function loadMore() {
    if (state.loading || !state.hasMore || !state.currentChannel || state.currentChannel?.id == undefined) return;
    console.log("calling loadmore")
    dispatch({ type: "SET_LOADING", payload: true });

    const channelQuery = new Parse.Query("PostInChannel");
    channelQuery.equalTo("channel", state.currentChannel);
    channelQuery.descending("createdAt");
    channelQuery.include("post");
    channelQuery.include("post.author");
    channelQuery.limit(POSTS_PER_PAGE);
    channelQuery.skip(state.loadedPosts.length);

    try {
      const results = await channelQuery.find();
      console.log("found results!", results);
      dispatch({
        type: "SET_LOADED_POSTS",
        payload: results,
      });
    } catch (error) {
      console.error("Error fetching posts: ", error);
      dispatch({ type: "SET_LOADING", payload: false });
    }
  }

  useEffect(() => {
    if (!state.loading && state.loadedPosts.length === 0) {
      console.log("calling loadmore from useeffect");
      loadMore();
    }
  }, [state.currentChannel]);

  const loading = state.loading;
  const loadedPosts = state.loadedPosts;

  const VirtuosoHeader = ({ context }) => {
    const {
      state,
      dispatch,
      isChannelCreator,
      handleImageUpload,
      loading,
      loadedPosts,
    } = context;

    if (!state.currentChannel) return null;

    return (
      <ChannelFeedHeader
        state={state}
        dispatch={dispatch}
        isChannelCreator={isChannelCreator}
        handleImageUpload={handleImageUpload}
        loading={state.loadingChannelData}
        loadedPosts={state.loadedPosts}
      />
    );
  };

  const Footer = ({ context }) => {
    const { state, isMobileApp } = context;
    const { loading } = state;
    return (
      loading ? (
        <div className={`w-full h-full ${!isMobileApp && "max-w-[720px] flex flex-col justify-center mx-auto"}`}>
          <LoadingSkeleton />
        </div>
      ) : <div className="bg-transparent h-[80px]"></div> // if not loading add 80px tall div to footer for mobilebottomnav lol
    );
  };

  const context = {
    state,
    dispatch,
    isChannelCreator,
    handleImageUpload,
    loading,
    loadedPosts,
  };

  const leftAction = isMobileApp ? () => { } : () => history.go(-1);

  const channelBackground = state.currentChannel?.get("backgroundImage");
  let backgroundStyle;

  if (channelBackground) {
    backgroundStyle = { backgroundImage: `url(${channelBackground})` };
  } else if (state.color2) {
    backgroundStyle = { backgroundImage: `linear-gradient(to bottom, ${state.color1}, ${state.color2})` };
  } else {
    backgroundStyle = { backgroundColor: state.color1 };
  }

  return (
    <IonPage className="profilePage w-full h-full">
      <div
        className={`absolute left-0 w-full h-full`}
        style={{
          ...backgroundStyle,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          height: 'calc(100vh + 100px)',
          marginTop: "calc(-1 * env(safe-area-inset-top))",
        }}
      />
      <Header
        isActivePage={true}
        leftIcon={<ChevronLeft className={`w-6 h-6 cursor-pointer fill-seam-${state.fontColor ? state.fontColor : 'black'}`} />}
        rightAction={() => { }}
        leftAction={leftAction}
        rightIcon={
          state.currentChannel && account && (
            <div className="relative">
              {state.currentChannel.get("creator").id === account.id ? (
                <>
                  <SeamComposer
                    composerTitle={"Post something to your collection"}
                    communityName={state.currentChannel.get("name")}
                    communityId={account.get("profileId")}
                    currentCard={undefined}
                    channel={state.currentChannel}
                    completion={handlePostCompletion}
                  />
                  {/* Arrow Image Positioned Absolutely */}
                  {(!state.loading && !state.currentChannel.get("isDeleted") && loadedPosts.length === 0) && (
                    <img
                      src={CollectionArrow}
                      alt="Collection Arrow"
                      className="absolute top-full mt-2 right-4"
                    />
                  )
                  }
                </>
              ) : (
                <button
                  onClick={state.isFollowing ? handleUnfollow : handleFollow}
                  className="py-2 px-4 bg-seam-blue text-white rounded-[38px]"
                >
                  <span className="flex flex-row items-center justify-center space-x-2">
                    <PersonAddAlt1 className="fill-white w-4 h-4" />
                    <h4>{state.isFollowing ? "Unfollow" : "Follow"}</h4>
                  </span>
                </button>
              )}
            </div>
          )
        }
        showSearchBar={null}
        onSearchSubmit={() => { }}
        scrollToTop={() => { }}
        leftIconColor={"transparent"}
        rightIconColor={null}
        centerIcon={<Typography variant="h3" className={`text-seam-${state.fontColor}`}>{state.currentChannel?.get("emoji")}&nbsp;{state.currentChannel?.get("name") || ''}</Typography>}
      />
      <IonContent fullscreen={true} scrollY={false}>
        <DesktopSidebarWrapper>
          <div className="h-full w-full overflow-y-auto flex items-start justify-start">
            {state.currentChannel?.get("isDeleted") ? (
              <div className="h-full w-full flex items-center justify-center">
                <Typography variant="h2" className="text-[#8A8A8A]">This collection has been deleted.</Typography>
              </div>
            ) : (
              <Virtuoso
                className="hide-scrollbar w-full"
                data={state.loadedPosts}
                endReached={loadMore}
                overscan={3}
                components={{
                  Header: VirtuosoHeader,
                  Footer,
                }}
                context={context}
                itemContent={(index, post) => {
                  const currentPost = post.get("post");
                  const postData = currentPost ? currentPost.get("postData") : null;
                  if (!postData) {
                    console.warn(`Post ${post.id} is missing postData`);
                    return null;
                  }
                  return (
                    <div key={currentPost.id} className="w-full h-full max-w-[720px] m-auto mb-4 px-4">
                      <FeedItem post={currentPost} collection={state.currentChannel} postInCollection={post} isOnChannelPage={true} />
                    </div>
                  )
                }}
              />
            )
            }
          </div>
          {state.currentChannel &&
            <ChannelPageModal
              currentChannel={state.currentChannel}
              isChannelCreator={isChannelCreator}
              state={state}
              dispatch={dispatch}
              modalOpen={state.modalOpen}
            />}
        </DesktopSidebarWrapper>
      </IonContent>
    </IonPage>
  );
}

export default ChannelPage;