import React, { useState, useRef } from "react";
import { IonHeader, IonSearchbar, IonToolbar } from "@ionic/react";
import logo from "../assets/logo.svg";
import { ChevronLeft, Search } from "react-feather";
import { useSeamNavigator } from "./SeamNavigatorContext";
import { useMobile } from "../utils/MobileContext";
import SearchDropdown from "../Discover/SearchDropdown";

export default function Header({
  isActivePage,
  leftIcon,
  rightAction,
  leftAction,
  rightIcon,
  showSearchBar,
  onSearchSubmit,
  scrollToTop,
  rightIconColor,
  leftIconColor,
  centerIcon,
}) {
  const { isMobile, isMobileApp } = useMobile();
  const [searchTerm, setSearchTerm] = useState('');
  const seamNavigator = useSeamNavigator();
  const searchbarRef = useRef(null);

  const handleInput = (event) => {
    // Access the input value directly from the event target
    const value = event.target.value;
    setSearchTerm(value);
  };

  function LeftIcon() {
    const handleLeftClick = () => {
      if (isMobileApp && seamNavigator.canGoBack()) {
        seamNavigator.goBack();
      } else {
        // if not on mobile app, use the provided leftAction, typically history.go(-1)
        leftAction();
      }
    };

    return (
      <div onClick={handleLeftClick} className={`flex justify-start items-center rounded-full ${leftIcon && 'bg-' + (leftIconColor ? leftIconColor : '[#efefef]')}`} style={{ marginRight: '16px' }}>
        {leftIcon}
      </div>
    );
  }

  function RightIcon(rightIconColor) {
    return (
      <div onClick={rightAction} className={`flex max-w-justify-end items-center rounded-full ${rightIcon && 'bg-' + (rightIconColor ? rightIconColor : '[#ea3bf7]')}`} style={{ marginLeft: '16px' }}>
        {isActivePage && rightIcon}
      </div>
    );
  }

  const handleCenterIconClick = () => {
    if (scrollToTop) {
      scrollToTop();
    } else {
      {!isMobileApp && seamNavigator.navigateTo("/")}
    }
  };

  return (
    <IonHeader class="ion-no-border border-b-[2px] bg-transparent border-seam-black/[5%] h-[54px]">
      {/* Extend border using a pseudo-element */}
      {showSearchBar ? (
        <>
          <div ref={searchbarRef} className="flex relative items-center justify-between h-full w-full">
            <IonSearchbar
              showCancelButton="always"
              onIonCancel={() => leftAction()}
              onIonChange={(e) => onSearchSubmit(e.detail.value)}
              onIonInput={handleInput}
              placeholder="Search on Seam"
              debounce={300}
              class="homeSearch"
            ></IonSearchbar>
          </div>
          <div className="w-full h-full">
            {searchTerm && <SearchDropdown searchTerm={searchTerm} />}
          </div>
        </>
      ) : (
        <div className="flex items-center justify-between px-4 w-auto h-full bg-transparent">
          <LeftIcon />
          <div style={{ maxWidth: '50%', position: 'absolute', left: '50%', transform: 'translateX(-50%)' }} onClick={handleCenterIconClick} className="cursor-pointer">
            {centerIcon ? centerIcon : <img src={logo} alt="Seam Logo" className="h-[44px]" />}
          </div>
          {rightIcon && RightIcon(rightIconColor)}
          {!rightIcon && <div className="w-[38px]"></div>}
        </div>
      )}
    </IonHeader>
  );
}