import React, { useEffect, useState, useRef } from 'react';
import { useSeamUser } from '../utils/SeamUserContext';
import { useMobile } from '../utils/MobileContext';
import { Avatar, Divider } from '@mui/material';
import { IonPage, IonContent, IonHeader } from '@ionic/react';
import mixpanel from 'mixpanel-browser';

const WelcomeFromWebCreatedAccountPage = () => {
  const { account, refreshAccount } = useSeamUser();
  const { isMobile, isMobileApp } = useMobile();

  const handleButtonClick = () => {
    mixpanel.track("onboarding user downloaded app");
    account.set("isOnboarded", true); // this will trigger the internalPage to advance
    account.save().then((savedAccount) => {
      refreshAccount()
    });
  }

  return (
    <IonPage className="w-full" style={{ marginTop: "calc(-1 * env(safe-area-inset-top))", paddingBottom: "0px" }}>
      <IonContent fullscreen={true} scrollX={false} scrollY={false} className="w-full h-full px-4">
        <IonHeader></IonHeader>
        <div className="w-full h-full bg-seam-blue items-center justify-between flex flex-col">
          <div className={`flex flex-col w-full max-w-[720px] px-4 h-auto justify-center items-center text-center ${isMobileApp ? 'mt-[120px]' : 'mt-0'}`}>
            <div className="flex flex-row items-end justify-center mt-auto w-auto h-auto relative">
              <Avatar src={account.get("profilePhoto")} className="w-[106px] h-[106px] border-4 border-seam-white mt-[120px]" />
            </div>
            <div className="mb-[48px] w-full">
              <h1 className="text-seam-white" style={{ marginTop: '32px' }}>{"Hi, " + account.get("profileId") + "!"}</h1>
              <h3 className="text-seam-white px-2" style={{ marginTop: '16px' }}>We've saved everything for you, let's dive into Seam!</h3>
            </div>
          </div>
          <div
            onClick={handleButtonClick}
            style={{ marginBottom: '36px', width: '90%' }}
            className="py-2 flex items-center max-w-[720px] justify-center text-center cursor-pointer border-2 w-full border-seam-white rounded-[8px]"
          >
            <h3 className="text-seam-white w-full text-center py-4">Customize my profile</h3>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}

export default WelcomeFromWebCreatedAccountPage;