import React, { useState, useEffect, useRef, useReducer, forwardRef, useImperativeHandle } from "react";
import FeedItem from "./FeedItem";
import Parse from 'parse';
import { useSeamUser } from "../utils/SeamUserContext";
import { Virtuoso } from 'react-virtuoso';
import HomeFeedHeader from "./HomeFeedHeader";
import PullToRefresh from 'react-simple-pull-to-refresh';
import { LoadingSkeleton } from "../components/LoadingSkeleton";
import { useMobile } from "../utils/MobileContext";
import { motion, useAnimation } from "framer-motion";

const FeedType = {
  global: "global",
  friends: "friends",
}

const POSTS_PER_PAGE = 10;
const initialState = {
  feedType: FeedType.friends,
  loadedPosts: [],
  hasMore: true,
  isLoading: false,
  refresh: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_FEED_TYPE": {
      return { ...state, feedType: action.payload, loadedPosts: [], hasMore: true };
    }
    case "REFRESH": {
      return { ...state, loadedPosts: [], hasMore: true, refresh: true };
    }
    case "POSTS_LOADED": {
      return {
        ...state,
        loadedPosts: [...state.loadedPosts, ...action.payload],
        isLoading: false,
        hasMore: action.payload.length === POSTS_PER_PAGE,
        refresh: false,
      };
    }
    case 'SET_LOADED_POSTS':
      return { ...state, loadedPosts: action.payload };
    case 'ADD_LOADED_POST':
      return { ...state, loadedPosts: [action.payload, ...state.loadedPosts] };
    case 'SET_IS_LOADING':
      return { ...state, isLoading: action.payload };
    default:
      throw new Error();
  }
}

const Header = ({ context }) => {
  const { state, controls, handleButtonClick, account } = context;
  return (
    <HomeFeedHeader
      state={state}
      controls={controls}
      handleButtonClick={handleButtonClick}
      account={account}
    />
  );
};

const Footer = ({ context }) => {
  const { isLoading, isMobileApp } = context;
  return (
    isLoading ? (
      <div className={`w-full h-full ${!isMobileApp && "max-w-[720px] flex flex-col justify-center mx-auto"}`}>
        <LoadingSkeleton />
      </div>
    ) : null
  );
};

const DiscoveryFeed = forwardRef((props, ref) => {
  const { account, first500UserFollowedChannels } = useSeamUser();
  const { isMobileApp, isMobile } = useMobile();
  const controls = useAnimation();

  const [state, dispatch] = useReducer(reducer, initialState);
  const blockedUsers = account?.get("blockedUsers") ?? [];
  const virtuosoRef = useRef();

  useImperativeHandle(ref, () => ({
    scrollToIndex: (index) => {
      virtuosoRef.current.scrollToIndex(index);
    },
  }));

  useEffect(() => {
    if (first500UserFollowedChannels.length == 0) return;
    if (!state.isLoading && state.loadedPosts.length === 0) {
      loadMore();
    }
  }, [first500UserFollowedChannels, state.feedType, state.refresh])

  useEffect(() => {
    // optimistically remove deleted posts
    window.emitter.on("SEAM_EVENT_DELETE_POST", (post) => {
      if (!post) return;
      if (post.get("groupLocationId") == undefined) return; // never optimistically delete group posts
      dispatch({ type: "SET_LOADED_POSTS", payload: state.loadedPosts.filter(prevPost => prevPost.id != post.id) });
    });
    window.emitter.on("SEAM_EVENT_POST_SUCCESSFUL", (newPost) => {
      if (!newPost) return;
      if (newPost.get("groupLocationId") != undefined) return; // never optimistically add group posts
      dispatch({ type: "ADD_LOADED_POST", payload: newPost });
    });
  }, []);

  async function loadMore() {
    if (state.isLoading || !state.hasMore || first500UserFollowedChannels.length == 0) return;
    dispatch({ type: "SET_IS_LOADING", payload: true });
    let query = new Parse.Query("PostInChannel");
    query.containedIn("channel", first500UserFollowedChannels);
    query.include("post");
    query.include("post.author");
    query.include("channel")
    query.descending("createdAt");
    query.limit(POSTS_PER_PAGE);
    query.skip(state.loadedPosts.length);

    query.find().then((results) => {
      dispatch({ type: "POSTS_LOADED", payload: results });
    });
  }

  function handleRefresh() {
    return new Promise(resolve => {
      dispatch({ type: "REFRESH" });
      setTimeout(() => {
        resolve();
      }, 1000);
    });
  }

  const handleButtonClick = async (feedType) => {
    if (feedType === state.feedType) return; // Prevent re-triggering animation if the same button is clicked
    dispatch({ type: 'SET_FEED_TYPE', payload: feedType });
    await controls.start({
      width: "100%",
      transition: { duration: 0.3, ease: "easeInOut" }
    });
    await controls.start({
      left: feedType === FeedType.friends ? "0%" : "50%",
      transition: { duration: 0.3, ease: "easeInOut" }
    });
    await controls.start({
      width: "50%",
      transition: { duration: 0.3, ease: "easeInOut" }
    });
  };

  const context = { state, controls, handleButtonClick, account, isLoading: state.isLoading, isMobileApp };

  return (
    <PullToRefresh
      onRefresh={handleRefresh}
      pullDownThreshold={75}
      maxPullDownDistance={175}
      resistance={2}>
      <Virtuoso
        ref={virtuosoRef}
        className="hide-scrollbar w-full ion-content-scroll-host"
        data={state.loadedPosts}
        endReached={loadMore}
        overscan={6}
        itemContent={(index, postInCollection) => {
          return (<div key={postInCollection.id} className="w-full max-w-[720px] h-auto m-auto pt-4 px-4 bg-white rounded">
            <FeedItem post={postInCollection.get("post")} collection={postInCollection.get("channel")} postInCollection={postInCollection} />
          </div>)
        }}
        increaseViewportBy={{ top: 800, bottom: 300 }} //https://github.com/petyosi/react-virtuoso/issues/914
        components={{
          Header,
          Footer,
        }}
        context={context}
      />
    </PullToRefresh>
  );
});

export default DiscoveryFeed;