import { memo } from "react";
import SeamEngagementBar from "../Comment/EngagementBar/SeamEngagementBar";
import SeamUserFeedItem from "../InternalBlocks/WallBlock/SeamUserFeedItem";

function FeedItem(props) {
  const { post, collection, postInCollection, isOnPostDetailPage = false, isOnChannelPage = false } = props;
  const DeletedPost = (
    <div className="h-12 flex items-center justify-center bg-seam-gray text-seam-black rounded-xl">
      This post has been deleted
    </div>
  )

  if (post == undefined) {
    return null
  }

  return (
    <div
      key={post?.id}
      className="flex flex-col items-start p-2 rounded-[20px] border border-gray-200 bg-[#FCFCFC] shadow-none"
    >
      <div className="w-full h-full max-w-full overflow-clip">
        {post?.get("isDeleted") ? (
          DeletedPost
        ) : (
          <SeamUserFeedItem {...props} />
        )}
      </div>
      <div className="w-full mt-2">
        <SeamEngagementBar post={post} postInChannel={postInCollection} isOnPostDetailPage={isOnPostDetailPage} />
      </div>
    </div>
  );
}

export default memo(FeedItem);
