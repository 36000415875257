import React, { useState, useEffect, memo } from 'react';
import Parse from 'parse';
import { IonPage, IonContent, IonModal, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import Header from '../Navigation/Header';
import { ChevronLeft, LibraryAddOutlined, PaletteOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import ProfilePageHeader from './ProfilePageHeader';
import { useSeamUser } from '../utils/SeamUserContext';
import { useSeamNavigator } from '../Navigation/SeamNavigatorContext';
import { useHistory } from 'react-router';
import ProfileSettingsModal from './ProfileSettingsModal';
import CollectionsFeed from './CollectionsFeed';
import SeamChannelRepository from '../Channels/SeamChannelRepository';
import "./Profile.css"
import DesktopSidebarWrapper from '../components/DesktopSidebarWrapper';
import { useMobile } from '../utils/MobileContext';
import FriendRequestButton from '../InternalBlocks/FriendRequestButton';
import WelcomeFromWebCreatedAccountPage from '../components/WelcomeFromWebCreatedAccountPage';

const UserProfilePage = ({ username }) => {
  const { account } = useSeamUser();
  const { isMobile, isMobileApp } = useMobile();
  const history = useHistory();
  const { contextObject, navigateTo } = useSeamNavigator();
  const [creatorAccount, setCreatorAccount] = useState(contextObject?.get("profileId") == username ? contextObject : undefined);
  const [isActivePage, setIsActivePage] = useState(true);
  const [showSettings, setShowSettings] = useState(false);
  const [profileCustomizations, setProfileCustomizations] = useState(undefined);
  const [isSelfProfile, setIsSelfProfile] = useState(username === account?.get("profileId"));

  useEffect(() => {
    const fetchCreatorAccount = async () => {
      const userQuery = new Parse.Query("Account");
      userQuery.equalTo("profileId", username);
      const user = await userQuery.first();
      setCreatorAccount(user);
    };

    if (username && username != creatorAccount?.get("profileId")) {
      fetchCreatorAccount();
    }
    const selfProfile = username && username === account?.get("profileId")
    if (account && selfProfile != isSelfProfile) {
      setIsSelfProfile(username === account?.get("profileId"));
    }
  }, [username, account]);

  useEffect(() => {
    const fetchCreatorCustomizations = async () => {
      const profileCustomizationsQuery = new Parse.Query("ProfileCustomizations");
      profileCustomizationsQuery.equalTo("account", creatorAccount);
      const profileCustomizations = await profileCustomizationsQuery.first();
      setProfileCustomizations(profileCustomizations || null);
    }
    fetchCreatorCustomizations();
  }, [creatorAccount]);

  useIonViewWillEnter(() => {
    setIsActivePage(true);
  });

  useIonViewWillLeave(() => {
    setIsActivePage(false);
  });

  if (creatorAccount === undefined) {
    return <></>
  }

  // for desktop onboarding
  if (account && account.get("isOnboarded") !== true) {
    //resurrect old users here

    return <WelcomeFromWebCreatedAccountPage />
  }

  const leftAction = isMobileApp ? () => { } : () => history.go(-1);

  // Check for either the background image or the color gradient
  const backgroundImage = profileCustomizations?.get("backgroundImage");
  const color1 = profileCustomizations?.get("color1") || "#FFFFFF";
  const color2 = profileCustomizations?.get("color2") || "undefined";
  let backgroundStyle;
  if (backgroundImage) {
    backgroundStyle = { backgroundImage: `url(${backgroundImage})` };
  } else if (color2 && color2 !== 'undefined' && color2 !== '') {
    backgroundStyle = { backgroundImage: `linear-gradient(to bottom, ${color1}, ${color2})` };
  } else {
    backgroundStyle = { backgroundColor: color1 };
  }  

  return (
    <IonPage className="profilePage w-full h-full">
      <div className={`absolute left-0 w-full h-full`}
        style={{
          ...backgroundStyle,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          height: 'calc(100vh + 100px)',
          marginTop: 'calc(-1 * env(safe-area-inset-top))',
        }}
      />
      <Header
        isActivePage={isActivePage}
        leftIcon={isSelfProfile ?
          <div onClick={() => setShowSettings(true)} className="flex cursor-pointer flex-row items-center justify-center space-x-2 py-2 px-2 bg-seam-white border-[1px] border-seam-gray rounded-[24px]">
            <PaletteOutlined className="fill-seam-black w-4 h-4" />
            <Typography variant="h4" className="text-seam-black">
              Customize
            </Typography>
          </div>
          :
          <ChevronLeft onClick={() => leftAction()} className="py-2 px-6 fill-seam-black cursor-pointer" />
        }
        rightIcon={isSelfProfile && account ?
          <div className="flex flex-row cursor-pointer items-center justify-center space-x-2 py-2 px-[20px] bg-seam-blue rounded-[24px]" onClick={() => {
            const emptyChannel = SeamChannelRepository.emptyChannel({
              creator: account,
              name: "New Collection",
              emoji: "😊",
              headerImage: "",
            });
            const id = emptyChannel.id;
            navigateTo(`/collection/${id}`, emptyChannel);
          }}>
            <LibraryAddOutlined
              className="w-4 h-4 fill-white"
            />
            <Typography variant="h4" className="text-seam-white">
              New
            </Typography>
          </div>
          : <div className="flex flex-row cursor-pointer items-center justify-center space-x-2">
            <FriendRequestButton creatorAccount={creatorAccount} variant="small" />
          </div>
        }
        leftAction={() => { }}
        centerIcon={<Typography variant="h3" className={`truncate px-6 max-w-full text-${profileCustomizations?.get("fontColor")}`}>@{creatorAccount.get("profileId")}</Typography>}
      />
      <IonContent fullscreen={true} scrollY={false}>
        <DesktopSidebarWrapper>
          <div className="w-full h-full flex flex-col items-center justify-start space-y-6">
            <div className={`w-full h-full pt-6 hide-scrollbar overflow-y-auto ${!isMobile && 'max-w-[720px]'}`}>
              <ProfilePageHeader creatorAccount={creatorAccount} profileCustomizations={profileCustomizations} />
              <CollectionsFeed creatorAccount={creatorAccount} profileCustomizations={profileCustomizations} />
            </div>
          </div>

          <IonModal
            isOpen={showSettings}
            onDidDismiss={() => setShowSettings(false)}
            swipeToClose={true}
            canDismiss={true}
            className={`h-full`}
            breakpoints={[0, 1]}
            initialBreakpoint={1}
          >
            <ProfileSettingsModal
              profileCustomizations={profileCustomizations}
              updateProfile={(updated) => { setProfileCustomizations(updated) }}
              close={() => setShowSettings(false)} />
          </IonModal>
        </DesktopSidebarWrapper>
      </IonContent>
    </IonPage>
  );
};

export default memo(UserProfilePage);