import { memo } from "react";
import { Link } from "react-router-dom";
import SeamUserItem from "../components/SeamUserItem";
import CommentItemOptions from "../Discover/CommentItemOptions";
import CommentEngagementBar from "./EngagementBar/CommentEngagementBar";
import { formatPostDate } from "../utils/formatPostDate";
import UiKitLinkify from "./Linkify";
import BlockFactory from "../Block-SDK/src/blocks/BlockFactory";
import Parse from 'parse';

const SeamComment = ({ comment, post, isOnCommentDetailPage }) => {
  const author = comment.get("author");
  if (!comment || !author || !post) {
    return null;
  }

  const postAuthor = post.get("author");
  const parentPostAuthor = postAuthor.get("profileId");
  const isDeleted = comment.get("isDeleted");

  const renderCommentTitle = () => {
    const profileId = author?.get("profileId") || "Deleted User";
    const profileLink = author ? `/user/${author.get("profileId")}` : "#";
    return (
      <Link
        to={profileLink}
        className="text-black font-normal text-base leading-normal hover:underline"
      >
        {profileId}
      </Link>
    );
  };

  const renderCommentSubtitle = () => {
    const timeInfo = (
      <span className="text-gray-500">
        &nbsp;· {formatPostDate(comment.createdAt)}
      </span>
    );
    const parentCommentAuthor = comment
    .get("parentComment")
    ?.get("author")
    ?.get("profileId");
    if (parentCommentAuthor) {
      return (
        <h4>
          Replying to{" "}
          <Link
            to={`/comment/${comment.get("parentComment")?.id}`}
            className="text-blue-500 hover:underline"
          >
            {parentCommentAuthor}'s comment
          </Link>
          {timeInfo}
        </h4>
      );
    } else {
      return (
        <div>
          <h4>
            Commented on{" "}
            <Link to={`/post/${post?.id}`} className="text-blue-500 hover:underline">
              {parentPostAuthor}'s post
            </Link>
            {timeInfo}
          </h4>
        </div>
      );
    }
  };

  const renderMiniappComment = () => {
    const miniappData = comment.get("miniappData");
    if (!miniappData) return null;

    const update = (miniappData) => {
      Parse.Cloud.run("updateCommentModel", {commentId: comment.id, updatedData: miniappData})
    }

    return (
      <div className="flex flex-row justify-center min-w-full min-h-full pt-2">
        {BlockFactory.getFeedComponent(miniappData, update)}
      </div>
    );
  };

  let commentStyle = isOnCommentDetailPage ? "px-5 py-4" : "px-5 py-2 border-b border-black border-opacity-5";

  return (
    <div className={commentStyle}>
      <SeamUserItem
        avatar={author?.get("profilePhoto") || "default-avatar-url"}
        title={renderCommentTitle()}
        subtitle={renderCommentSubtitle()}
        actions={CommentItemOptions({ comment })}
      />
      {isDeleted ? (
        <div className="flex justify-center items-center text-center text-sm text-gray-500 bg-gray-200 h-4 py-4 px-4 mt-4">
          Comment deleted
        </div>
      ) : (
        <div className="flex flex-col max-w-full w-full">
          <div className="text-black text-base font-normal leading-5 mt-2">
            <UiKitLinkify>{comment?.get("text")}</UiKitLinkify>
          </div>
          {renderMiniappComment()}
        </div>
      )}
      <div className="mt-2">
        <CommentEngagementBar comment={comment} isOnCommentDetailPage={isOnCommentDetailPage} />
      </div>
    </div>
  );
};

export default memo(SeamComment);