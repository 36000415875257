import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Box, CircularProgress, Stack, Typography } from "@mui/material";
import { UserPlus } from 'react-feather';
import { UserX } from 'react-feather';
import Parse from 'parse';
import { createFriendRequest } from '../utils/ConnectionRequestNetworkHelpers';
import * as Sentry from "@sentry/react";
import mixpanel from 'mixpanel-browser';
import { useSeamUser } from '../utils/SeamUserContext';
import { useSeamNavigator } from '../Navigation/SeamNavigatorContext';
import SeamChannelRepository from '../Channels/SeamChannelRepository';

const useStyles = makeStyles({
  addButton: {
    backgroundColor: '#0051E8',
    color: 'white',
    '&:hover': {
      backgroundColor: 'white',
      color: '#0051E8',
    },
  },
  removeButton: {
    backgroundColor: 'white',
    color: '#0051E8',
    '&:hover': {
      backgroundColor: '#0051E8',
      color: 'white',
    },
  },
});

const FriendRequestButton = ({ creatorAccount, variant }) => {
  const { account } = useSeamUser();
  const classes = useStyles();
  const [friendConnection, setFriendConnection] = useState(undefined);
  const [pendingConnection, setPendingConnection] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [optimisticRequest, setOptimisticRequest] = useState(false);
  const [optimisticBreakup, setOptimisticBreakup] = useState(false);
  const isAuthenticated = Parse.User.current() != undefined;
  const seamNavigator = useSeamNavigator();

  useEffect(() => {
    let isMounted = true; // To avoid setting state on unmounted component
    setIsLoading(true); // Start loading when component mounts or creatorAccount changes

    if (creatorAccount && account) {
      // Existing connections check
      const connectionsRelation = creatorAccount.relation("Connections");
      const query = connectionsRelation.query();
      query.equalTo("objectId", account?.id);
      query.first().then(result => {
        if (isMounted) setFriendConnection(result);

        // Pending connections check
        const recipientQuery = new Parse.Query("PartialConnection");
        recipientQuery.equalTo("recipient", account);
        recipientQuery.equalTo("initiator", creatorAccount);

        const initiatorQuery = new Parse.Query("PartialConnection");
        initiatorQuery.equalTo("initiator", account);
        initiatorQuery.equalTo("recipient", creatorAccount);

        const mainQuery = Parse.Query.or(recipientQuery, initiatorQuery);
        mainQuery.first().then(result => {
          if (isMounted) {
            setPendingConnection(result);
            setIsLoading(false); // Finished loading
          }
        }).catch(error => {
          if (isMounted) {
            console.log(error);
            Sentry.captureException(error);
            setIsLoading(false); // Finished loading in case of error
          }
        });
      }).catch(error => {
        if (isMounted) {
          console.log(error);
          Sentry.captureException(error);
          setIsLoading(false);
        }
      });
    } else {
      if (isMounted) setIsLoading(false); // If conditions not met, ensure loading is false
    }

    return () => { isMounted = false; }; // Cleanup function to avoid memory leaks
  }, [creatorAccount, account]);


  const addConnection = () => {
    if (window.confirm("Do you want to send a friend request to " + creatorAccount.get("name") + "?")) {
      // Add to partial connections table
      createFriendRequest(account, creatorAccount);
      setOptimisticRequest(true)
    }
  }

  const deleteConnection = async () => {
    if (window.confirm("Are you sure you want to break up with " + creatorAccount.get("name") + "?")) {
      setOptimisticBreakup(true);
      try {
        // Run the removeConnection cloud function to unfriend
        await Parse.Cloud.run("removeConnection", { account1: account.id, account2: creatorAccount.id });
  
        // Fetch all channels created by the unfriended account
        const channelQuery = new Parse.Query("Channel");
        channelQuery.equalTo("creator", creatorAccount); // Fetch channels created by creatorAccount
        const channels = await channelQuery.find();
  
        // Unfollow each channel
        const unfollowPromises = channels.map(channel => 
          SeamChannelRepository.unsubscribeToChannel(channel, account, () => {
            console.log(`Unsubscribed from channel: ${channel.get("name")}`);
          }, (error) => {
            console.error(`Error unsubscribing from channel: ${channel.get("name")}`, error);
          })
        );
        await Promise.all(unfollowPromises); // Ensure all unfollow actions are completed
  
        console.log("Unfollowed all channels created by the unfriended account.");
      } catch (error) {
        console.error("Error during deleteConnection or unfollowing channels", error);
      }
    }
  };  

  const acceptFriendRequest = () => {
    Parse.Cloud.run("acceptConnectionRequest", { connectionId: pendingConnection.id }).then((result) => {
      alert("Awesome - you're friends with " + creatorAccount.get("name") + "!")
      setPendingConnection(undefined)
      setFriendConnection(result)
    })
  }

  const denyFriendRequest = () => {
    if (window.confirm("Do you want to cancel the connection to " + creatorAccount.get("name") + "?")) {
      Parse.Cloud.run("denyConnectionRequest", { connectionId: pendingConnection?.id }).then((result) => {
        setPendingConnection(undefined)
      })
    }
  }

  const isFriends = optimisticBreakup ? false : friendConnection != undefined;
  const isOutstanding = optimisticRequest || (pendingConnection && pendingConnection.get("initiator").id == account?.id);
  const isPending = (pendingConnection && pendingConnection.get("recipient").id == account?.id);
  let friendsLabel = isFriends ? "Remove" : "Add";
  if (isPending) { friendsLabel = "Accept"; }
  if (isOutstanding) { friendsLabel = "Cancel"; }
  let friendsIcon = isFriends ? <UserX size={16} className="mr-2" stroke="white" /> : <UserPlus size={16} className="mr-2" />;
  if (isPending) { friendsIcon = <UserPlus size={22} />; }
  if (isOutstanding) { friendsIcon = <UserX size={22} className="mr-2" />; }

  const onTap = () => {
    // if the person tapping add friend isn't logged into Seam yet, send them to the homepage (with a referral)
    if (!isAuthenticated) {
      const url = '/?ref=' + creatorAccount?.get('referralCode');
      seamNavigator.navigateTo(url)
      return
    }

    if (account === undefined) {
      alert("You haven't created an account yet! Try logging out and logging back in again.");
      return
    }

    if (isOutstanding) {
      denyFriendRequest()
      mixpanel.track("friend request denied");
    } else if (isPending) {
      acceptFriendRequest()
      mixpanel.track("friend request accepted");
    } else if (isFriends) {
      deleteConnection()
      mixpanel.track("removed friend");
    } else {
      addConnection()
      mixpanel.track("friend requested");
    }
  }

  const smallAddButtonClasses = "text-white bg-seam-blue p-2 pl-4 pr-4 border-2 border-seam-black/[5%] rounded-full flex-1";
  const smallRemoveButtonClasses = "bg-blue hover:bg-blue-500 p-2 pl-4 pr-4 hover:text-white border border-seam-gray rounded-full flex-1"; 
  const bigAddButtonClasses = "flex w-auto justify-center items-center gap-4 p-4 pt-[18px] pb-[18px] rounded-lg bg-seam-blue text-white color-white"
  const bigRemoveButtonClasses = "flex w-auto justify-center items-center gap-4 p-4 pt-[18px] pb-[18px] rounded-lg border border-seam-gray"

  const isAddingFriend = (!isFriends && !isOutstanding && !isPending)
  const smallButtonClass = isAddingFriend && !isLoading ? smallAddButtonClasses : smallRemoveButtonClasses;
  const bigButtonClass = isAddingFriend && !isLoading ? bigAddButtonClasses : bigRemoveButtonClasses;

  if (!account || account === undefined) return null; 

  return (
    <div
      className={variant === "big" ? bigButtonClass : smallButtonClass}
      onClick={() => { onTap() }}
    >
      <Stack direction={"row"} style={{ display: 'flex', alignItems: 'center', }}>
        {isLoading ? <CircularProgress size={22} /> :
          <div className="w-auto flex flex-row px-1 items-center">
            <div>{friendsIcon}</div>
            <Typography variant="h4" className={isAddingFriend ? "text-white" : "text-white"}>{friendsLabel}</Typography>
          </div>
        }
      </Stack>
    </div>
  );
};

export default FriendRequestButton;