import React, { useEffect, useState } from "react";
import Parse from "parse";
import FeedItem from "./FeedItem";
import InfiniteScroll from "react-infinite-scroll-component";
import { useMobile } from "../utils/MobileContext";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { useSeamUser } from "../utils/SeamUserContext";
import { Virtuoso } from "react-virtuoso";

const SearchFeed = ({ tagName }) => {
  const { isMobile, isMobileApp } = useMobile();
  const { account } = useSeamUser();
  const [posts, setPosts] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const POSTS_PER_PAGE = 10;
  const decodedTagName = decodeURIComponent(tagName);

  const useStyles = makeStyles({
    feedStyle: {
      marginTop: isMobile ? "0px" : "64px",
      maxHeight: "100%",
      overflow: "hidden",
      maxWidth: isMobile ? "" : "33vw",
      marginLeft: isMobile ? "16px" : "auto",
      marginRight: isMobile ? "16px" : "auto",
      alignItems: "center",
      justifyContent: "center",
    },
    noScrollBar: {
      "&::-webkit-scrollbar": {
        display: "none"
      },
      "-ms-overflow-style": "none",  /* IE and Edge */
      "scrollbar-width": "none",  /* Firefox */
    },
  });

  const classes = useStyles(isMobile);

  useEffect(() => {
    // Reset state for new search term
    setPage(0);
    setPosts([]);
    setHasMore(true);

    // Now fetchPostsByTag will be called indirectly via the page effect
  }, [decodedTagName]);

  useEffect(() => {
    const fetchPostsByTag = async () => {
      console.log(
        "running fetchposts by tag with this tag name!: ",
        decodedTagName
      );
      try {
        const result = await Parse.Cloud.run("fetchPostsByTag", {
          tagName: decodedTagName,
          blockedTags: account.get("blockedTags"),
          skip: page * POSTS_PER_PAGE,
          limit: POSTS_PER_PAGE,
        });
        const postsArray = Array.isArray(result) ? result : [];
        console.log("posts array!:", postsArray);
        if (postsArray.length < POSTS_PER_PAGE) {
          setHasMore(false);
        }
        setPosts((prevPosts) => [...prevPosts, ...postsArray]);
      } catch (error) {
        console.error("Failed to fetch posts by tag: ", error);
      }
    };

    fetchPostsByTag();
    console.log("these are the posts!: ", posts);
  }, [page, decodedTagName]);

  const loadMorePosts = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
      <Virtuoso
        style={isMobileApp ? { height: window.innerHeight - 110 } : { height: window.innerHeight }}
        className={`${classes.noScrollBar} ion-content-scroll-host`}
        data={posts}
        endReached={loadMorePosts}
        overscan={3}
        itemContent={(index, post) => {
          return (
            <div className="mb-4 w-full max-w-[720px] m-auto pt-4 px-4">
              <FeedItem
                key={post.id}
                post={post}
                ownerAccount={post.get("author")}
              />
            </div>
          );
        }}
      />
  );
};

export default SearchFeed;
