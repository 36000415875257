import { IonModal } from "@ionic/react";
import { Typography, IconButton, Button, TextField } from "@mui/material";
import React, { useState, useRef } from 'react';
import { IOSSwitch } from '../components/IOSSwitch';
import { ChromePicker, HuePicker } from 'react-color';
import mixpanel from 'mixpanel-browser';
import { Clipboard } from "@capacitor/clipboard";
import SeamChannelRepository from "./SeamChannelRepository";
import { useSeamUser } from "../utils/SeamUserContext";
import { DeleteForever, Close } from "@mui/icons-material";
import ImageUploadComponent from "../components/ImageUploadComponent";
import { useSeamNavigator } from "../Navigation/SeamNavigatorContext";
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';

const ChannelPageModal = ({ currentChannel, isChannelCreator, state, dispatch, modalOpen, }) => {
  const { account } = useSeamUser();
  const seamNavigator = useSeamNavigator();
  const [color1, setColor1] = useState(state.color1 || '#FFFFFF');
  const [color2, setColor2] = useState(state.color2 || undefined);
  const [channelBackground, setChannelBackground] = useState(currentChannel?.get("backgroundImage") || null);
  const [linkCopied, setLinkCopied] = useState(false);
  const [localChannelName, setLocalChannelName] = useState(state.channelName || "Collection Name");
  const [emojiPickerVisible, setEmojiPickerVisible] = useState(false);
  const [activeColorPicker, setActiveColorPicker] = useState(null); 
  const inputRef = useRef(null);
  const url = `https://seam.so/collection/${currentChannel.id}`;

  const handleNameChange = (e) => {
    const newName = e.target.value;
    setLocalChannelName(newName);
  };

  const handleEmojiSelect = async (emoji) => {
    const selected = emoji.native;
    dispatch({
      type: "SET_CHANNEL_DATA",
      payload: { ...state, selectedEmoji: selected }
    });
    setEmojiPickerVisible(false);

    if (currentChannel) {
      currentChannel.set("emoji", selected);
      await currentChannel.save();
    }
  };

  const saveName = async () => {
    if (!localChannelName || localChannelName.trim() === "") return;
    currentChannel.set("name", localChannelName);
    await currentChannel.save();
    dispatch({
      type: "SET_CHANNEL_DATA",
      payload: { channelName: localChannelName }
    });
  };

  const handleCopyLink = async () => {
    try {
      await Clipboard.write({ string: url });
      setLinkCopied(true);
      setTimeout(() => setLinkCopied(false), 3000); // Reset after 3 seconds
      mixpanel.track("share via link", { 'url': url });
    } catch (err) {
      console.error("Failed to copy link: ", err);
    }
  };

  const saveProfile = () => {
    currentChannel.set("color1", color1);
    currentChannel.set("color2", color2);
    currentChannel.set("backgroundImage", channelBackground);
    currentChannel.save();

    dispatch({
      type: "SET_CHANNEL_DATA",
      payload: {
        ...state,
        color1: color1,
        color2: color2
      },
    });

    dispatch({
      type: "TOGGLE_MODAL",
      payload: false,
    })
  };

  const saveBackground = (url) => {
    setChannelBackground(url);
    currentChannel.set("backgroundImage", url);
    currentChannel.save();
  };

  const deleteBackground = () => {
    setChannelBackground(null);
    currentChannel.set("backgroundImage", null);
    currentChannel.save();
  };

  const handleFontColorChange = () => {
    const newFontColor = state.fontColor === "white" ? "black" : "white";
    currentChannel.set("fontColor", newFontColor);
    currentChannel.save();

    dispatch({
      type: "SET_CHANNEL_DATA",
      payload: { fontColor: newFontColor },
    });
  };

  const handleDeleteChannel = async () => {
    if (currentChannel) {
      try {
        window.emitter.emit("SEAM_EVENT_DELETED_COLLECTION", { channelId: currentChannel.id });

        SeamChannelRepository.deleteChannel(
          currentChannel,
          () => {
            seamNavigator.navigateTo(`/user/${account.get("profileId")}`);
            dispatch({
              type: "TOGGLE_MODAL",
              payload: false,
            });
          },
          (error) => {
            console.error("Failed to delete channel", error);
          }
        );
      } catch (error) {
        console.error("Failed to delete channel", error);
      }
    }
  };

  const popover = {
    position: 'absolute',
    zIndex: '2',
  };

  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  };

  const ConfirmButton = ({ onClick }) => (
    <Button
      variant="contained"
      color="primary"
      onClick={onClick}
      className="rounded-lg bg-seam-white text-seam-black hover-seam-gray"
      style={{
        width: '100%',
        padding: '10px',
        textAlign: 'center',
        borderTop: '1px solid #ddd',
        borderRadius: '0 0 4px 4px',
        marginTop: '-10px'

      }}
    >
      Confirm
    </Button>
  );

  const toggleColorPicker = (colorKey) => {
    if (activeColorPicker === colorKey) {
      setActiveColorPicker(null);
    } else {
      setActiveColorPicker(colorKey);
    }
  };

  const handleColorChange = (color, colorKey) => {
    const selectedColor = color === 'white' ? '#FFFFFF' : color === 'black' ? '#000000' : color.hex;
    if (colorKey === 'color1') {
      setColor1(selectedColor);
    } else if (colorKey === 'color2') {
      setColor2(selectedColor);
    }
  };  

  const subtitleStyle = "text-seam-gray-subtitle font-normal text-sm py-2";

  return (
    <IonModal
      isOpen={modalOpen}
      onDidDismiss={() => dispatch({ type: 'TOGGLE_MODAL', payload: false })}
      swipeToClose={true}
      canDismiss={true}
      className={`h-full`}
      breakpoints={[0, 0.75, 1]}
      initialBreakpoint={1}
    >
      {isChannelCreator ? (
        <>
          <div className="flex flex-col justify-between w-auto items-start h-auto space-y-4 px-4 py-6 box-border">
            {/* Header */}
            <div className="flex flex-row items-center justify-between space-x-4 w-full h-auto">
              <Typography variant="h2" className="text-seam-black p-4">
                Options
              </Typography>
              <div
                className="p-2 flex items-center justify-center w-auto h-auto bg-seam-gray rounded-full"
                onClick={async () => {
                  await saveProfile();
                }}
              >
                <Close className="w-4 h-4 text-seam-black" />
              </div>
            </div>
            {/* Section for Channel Name */}
            <div className="channel-name-section">
              <Typography variant="h4" className="text-[#86868A]">Edit Channel Name</Typography>
              <TextField
                fullWidth
                type="text"
                value={localChannelName}
                onChange={handleNameChange}
                onBlur={saveName}
                className={`text-seam-black w-full`}
                sx={{
                  "& .MuiInputBase-input": {
                    fontFamily: "Public Sans",
                    color: 'black'
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none", // This removes the border
                    },
                    "&:hover fieldset": {
                      border: "none", // Removes the border on hover
                    },
                    "&.Mui-focused fieldset": {
                      border: "none", // Removes the border when the component is focused
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: '#606060', // Label color
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: '#606060', // Ensures the label color remains the same when focused
                  },
                  paddingLeft: 0,
                }}
              />
            </div>

            {/* Section for Emoji Picker */}
            <div className="emoji-picker-section relative">
              <Typography variant="h4" className="text-[#86868A]">Edit Channel Emoji</Typography>
              <div
                onClick={() => setTimeout(() => { setEmojiPickerVisible(true) })}
                className="emoji-display cursor-pointer"
              >
                {state.selectedEmoji || "😀"}
              </div>

              {emojiPickerVisible && (
                <div className="absolute top-[120px] z-50">
                  <Picker
                    data={data}
                    autoFocus={true}
                    onClickOutside={() => setEmojiPickerVisible(false)}
                    onEmojiSelect={handleEmojiSelect}
                  />
                </div>
              )}
            </div>
            {/* Channel background */}
            <div>
              <Typography variant="h4" className="text-[#86868A]">Background</Typography>
            </div>
            <div className="flex flex-row w-full h-auto items-center justify-between space-x-4">
              <div className="w-[64px] h-[90px]">
                <ImageUploadComponent
                  fileTypes="image/*"
                  label="Upload"
                  onUpdate={(url) => saveBackground(url)}
                  backgroundImageUrl={channelBackground}
                />
              </div>
              {/* Channel background color gradient */}
              <div
                className={`flex w-full flex-col items-center justify-center border-[1px] rounded-[8px] h-[90px]`}
                style={{ backgroundColor: color1 }}
                onClick={() => toggleColorPicker('color1')} // Toggle for color 1
              >
                <Typography variant="h3" className="text-seam-black">Pick Color 1</Typography>
              </div>

              <div
                className={`flex w-full flex-col items-center justify-center border-[1px] rounded-[8px] h-[90px]`}
                style={{ backgroundColor: color2 }}
                onClick={() => toggleColorPicker('color2')} // Toggle for color 2
              >
                <Typography variant="h3" className="text-seam-black">Pick Color 2</Typography>
              </div>
              {channelBackground && (
                <IconButton onClick={deleteBackground} aria-label="delete" size="large">
                  <DeleteForever />
                </IconButton>
              )}
            </div>
            {activeColorPicker && (
              <div className="my-4 w-full flex flex-col items-start justify-start self-center">
                <h4 className={subtitleStyle}>Editing Color {activeColorPicker === 'color1' ? '1' : '2'}</h4>
                <div className="flex flex-row items-center justify-between w-full h-auto space-x-4">
                  
                  {/* White Button */}
                  <div
                    className="rounded-[8px] w-6 h-4 bg-white border-[1px] border-seam-black cursor-pointer"
                    onClick={() => handleColorChange('white', activeColorPicker)}
                  />
                  
                  <HuePicker
                    width='100%'
                    color={activeColorPicker === 'color1' ? color1 : color2} // Check which color to update
                    onChangeComplete={(color) => handleColorChange(color, activeColorPicker)}
                  />
                  
                  {/* Black Button */}
                  <div
                    className="rounded-[8px] w-6 h-4 bg-black border-[1px] border-seam-white cursor-pointer"
                    onClick={() => handleColorChange('black', activeColorPicker)}
                  />
                </div>
              </div>
            )}
            {/* Font Color Switch */}
            <Typography variant="h4" className="text-[#86868A]">Light Font</Typography>
            <IOSSwitch checked={state.fontColor === "white"} onChange={handleFontColorChange} />
            {/* Share / Delete collection buttons */}
            <div className="w-full h-auto flex flex-col items-center justify-center">
              <div
                onClick={handleCopyLink}
                className="w-full h-auto flex items-center justify-center focus:outline-none border-[1px] hover:bg-seam-gray border-[#000]/5 border-b-0"
              >
                {linkCopied ? (
                  <Typography variant="h3" className="text-seam-blue py-4">
                    Link Copied!
                  </Typography>
                ) : (
                  <Typography variant="h3" className="text-seam-black py-4">
                    Share Collection
                  </Typography>
                )
                }
              </div>
              <div
                onClick={handleDeleteChannel}
                className="w-full h-auto flex items-center justify-center border-[1px] border-[#000]/5 text-red-600 hover:bg-seam-gray focus:outline-none"
              >
                <Typography variant="h3" className="text-seam-red py-4">
                  Delete Collection
                </Typography>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="w-full h-auto flex flex-col items-center justify-center">
            <div
              onClick={handleCopyLink}
              className="w-full h-auto mt-[30px] flex items-center justify-center focus:outline-none border-[1px] hover:bg-seam-gray border-[#000]/5 border-b-0"
            >
              {linkCopied ? (
                <Typography variant="h3" className="text-seam-blue py-4">
                  Link Copied!
                </Typography>
              ) : (
                <Typography variant="h3" className="text-seam-black py-4">
                  Share Collection
                </Typography>
              )
              }
            </div>
          </div>
        </>
      )}
    </IonModal>
  )
}

export default ChannelPageModal;