import Tooltip from "@mui/material/Tooltip";
import React, { useState, useEffect } from "react";
import { Colors } from "../utils/colors";
import { makeStyles } from "@mui/styles";
import { useMobile } from '../utils/MobileContext';
import QuestionAnswerOutlined from "@mui/icons-material/Help";
import { usePrivy, useLogin } from '@privy-io/react-auth';
import { IconButton } from "@mui/material";
import SeamAuthenticator from "../Authentication/SeamAuthenticator";
import { ReactComponent as AppStoreBadge } from "../assets/AppStoreBadge.svg"
import { useIonRouter } from '@ionic/react';

const useStyles = makeStyles((theme) => ({
  help: {
    alignSelf: "end"
  }
}));

function LoginComponent({
  title,
  subtitle,
  referralAccount,
}) {
  const classes = useStyles();
  const { isMobile, isMobileApp } = useMobile();
  const mobileDesktop = isMobile && !isMobileApp
  const hasReferral = referralAccount != undefined
  const { logout, authenticated } = usePrivy()
  const router = useIonRouter();

  const { login } = useLogin({
    onComplete: (user, isNewUser, wasAlreadyAuthenticated) => {
      SeamAuthenticator.handlePrivyLogin(user, isNewUser).then((user) => {
      })
    },
    onError: (error) => {
      console.log(error);
    }
  })

  const handlePrivyAuth = async () => {
    if (authenticated) {
      logout()
    } else {
      login()
    }
  }

  return (
    <div className="flex flex-col w-full h-auto">
      <div
        className="w-full h-[56px] flex rounded-[8px] justify-center items-center bg-seam-white cursor-pointer"
        onClick={() => { handlePrivyAuth() }}
      >
        <h3 className="text-seam-blue">Sign in</h3>
      </div>
      <Tooltip title="Give feedback & get help logging in">
        <a href={"mailto:hello@getseam.xyz"} target="_blank" className={classes.help}>
          <IconButton style={{ alignSelf: "end" }}>
            <QuestionAnswerOutlined style={{ color: Colors.SEAM_GRAY_600 }} />
          </IconButton>
        </a>
      </Tooltip>
    </div>
  );
}

export default LoginComponent;