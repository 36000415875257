
export type QuestType = {
  name: string
  displayTitle: string
  description: string
  totalSteps: number
  value: number
}

export const QuestTypes: { [key: string]: QuestType } = {
  notifications: {
    name: "notifications", 
    displayTitle: "🔔 Notifications",
    description: "Turn on your notifications",
    totalSteps: 1,
    value: 30,
  },
  intro: {
    name: "intro",
    displayTitle: "👋 Intro",
    description: "Make a post with the '#intro' tag",
    totalSteps: 1,
    value: 30,
  },
  stitch: {
    name: "stitch",
    displayTitle: "🧵 Stitch",
    description: "Stitch a post that you like",
    totalSteps: 1,
    value: 10,
  },
  gif: {
    name: "gif",
    displayTitle: "📸 Profile Photo",
    description: "Use a GIF as your photo",
    totalSteps: 1,
    value: 10,
  },
  connections: {
    name: "connections",
    displayTitle: "👯‍♀️ Friends",
    description: "Add your first 5 friends",
    totalSteps: 5,
    value: 50,
  },
}