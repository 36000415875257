import React, { useState } from 'react';
import { Clipboard } from "@capacitor/clipboard";
import { Share } from "@capacitor/share";
import { Button, Box, Avatar } from "@mui/material";
import { MessageOutlined, IosShareOutlined, LinkOutlined, PlaylistAddCheck } from '@mui/icons-material';
import StitchIcon from "../../assets/icons/stitchIcon.svg";
import { useMobile } from '../../utils/MobileContext';
import mixpanel from 'mixpanel-browser';

const SeamComposerReblogBlockStep = ({ post }) => {
  const url = `https://seam.so/post/${post.id}`;
  const { isMobileApp } = useMobile();

  const [linkCopied, setLinkCopied] = useState(false);

  const handleCopyLink = async (url) => {
    try {
      await Clipboard.write({
        string: url
      });
      setLinkCopied(true);
      setTimeout(() => setLinkCopied(false), 3000); // Reset after 3 seconds
      mixpanel.track("share via link", { 'url': url });
    } catch (err) {
      console.error("Failed to copy link: ", err);
    }
  };

  const handleShare = async (url) => {
    try {
      await Share.share({
        title: 'Seam Post',
        text: 'Check out this post on Seam:',
        url: url,
        dialogTitle: 'Share with'
      });
      mixpanel.track("share via ios sheet", { 'url': url });
    } catch (err) {
      console.error('Error sharing', err);
    }
  };

  const handleIMessageShare = (url) => {
    window.location.href = `sms:&body=${url}`;
    mixpanel.track("share via sms", { 'url': url });
  };

  const ShareButton = ({ onClick, icon, label, isCopied }) => (
    <Button
      onClick={onClick}
      className={`flex-1 flex flex-col space-y-1 text-seam-black/50 border ${isCopied ? 'border-blue-500 bg-blue-100' : 'border-gray-300'} rounded-lg shadow-none normal-case`}
    >
      <div className={`p-2 ${isCopied ? 'bg-seam-blue' : 'bg-seam-gray'} rounded-full`}>
        {isCopied ? <PlaylistAddCheck className="fill-seam-white" /> : icon}
      </div>
      <h3 className={isCopied ? 'text-seam-blue' : ''}>{label}</h3>
    </Button>
  );

  return (
    <div className="flex flex-col w-auto h-full mx-4">
      <div className="w-auto flex flex-row h-auto items-center justify-center space-x-2">
        <img src={StitchIcon} className="h-6 w-6" />
        <h3 className="text-seam-black/50">Share</h3>
      </div>
      <div className="flex flex-row space-x-4 justify-between">
        <ShareButton onClick={() => handleIMessageShare(url)} icon={<MessageOutlined className="fill-seam-black" />} label="Messages" />
        {isMobileApp && <ShareButton onClick={() => handleShare(url)} icon={<IosShareOutlined className="fill-seam-black" />} label="Share to..." />}
        <ShareButton onClick={() => handleCopyLink(url)} icon={<LinkOutlined className="fill-seam-black" />} label={linkCopied ? "Copied!" : "Copy link"} isCopied={linkCopied} />
      </div>
    </div>
  );
};

export default SeamComposerReblogBlockStep;