import mixpanel from "mixpanel-browser";
import { QuestType } from "./QuestTypes";
import Parse from "parse";

interface Quest {
  [key: string]: { completed: number, total: number }
}

export default class QuestManager {
  static async completeMiniappQuest(tags: string[], blockType: string, account: Parse.Object) {
    const dailyQuests = await QuestManager.getDailyQuests();
    for (let i = 0; i < dailyQuests.length; i++) {
      const quest = dailyQuests[i];
      const questTag = quest.get("tag");
      if (quest.get("BlockType") !== blockType) {
        continue;
      }
      const isQuestComplete = QuestManager.isQuestCompleted(questTag, account)
      if (isQuestComplete) {
        continue;
      }
      Parse.Cloud.run("pointTransaction", { delta: quest.get("reward") * 3, accountId: account.id, reason: `Completed: ${questTag}` })
      account.addUnique("dailyQuests", questTag)
      account.save()
      mixpanel.track("Completed Quest", { quest: quest.get("description"), tag: questTag, miniapp: blockType })
    }
  }

  static async getDailyQuests() {
    const query = new Parse.Query("DailyQuest");

    // Get today's date at midnight UTC
    const startOfTodayUTC = new Date();
    startOfTodayUTC.setUTCHours(0, 0, 0, 0);

    // Get tomorrow's date at midnight UTC
    const endOfTodayUTC = new Date(startOfTodayUTC);
    endOfTodayUTC.setUTCDate(endOfTodayUTC.getUTCDate() + 1);

    query.greaterThanOrEqualTo("Date", startOfTodayUTC);
    query.lessThan("Date", endOfTodayUTC);

    const quests = await query.find();
    return quests;
  }

  static isQuestCompleted(tag: string, account: Parse.Object) {
    let quests = account?.get("dailyQuests")
    if (quests) {
      return quests.includes(tag)
    }
    return false
  }

  // TODO: Deprecate old quest logic
  static completeQuest(quest: QuestType, completedActions: number, account: Parse.Object) {
    if (quest === undefined || account === undefined) {
      if (process.env.NODE_ENV === 'development') { alert("Quest and account must be defined") }
    }

    let quests = account?.get("quests")

    // check if the user has already started the quest
    let existingQuestProgress = quests ? quests[quest.name] : undefined

    if (existingQuestProgress && existingQuestProgress['completed'] >= existingQuestProgress['total']) {
      return // quest has been completed, no need to update further
    }

    const isQuestBeingCompleted = (completedActions + (existingQuestProgress ? existingQuestProgress['completed'] : 0)) >= quest.totalSteps
    if (existingQuestProgress && existingQuestProgress['completed'] < existingQuestProgress['total']) {
      quests[quest.name]['completed'] += completedActions
      account?.set("quests", quests)
    } else {
      // create the new quest
      let newQuest: Quest = {};
      newQuest[quest.name] = { "completed": completedActions, "total": quest.totalSteps }
      const allQuests = Object.assign({}, quests, newQuest);
      account?.set("quests", allQuests)
    }

    if (isQuestBeingCompleted) {
      Parse.Cloud.run("pointTransaction", { delta: quest.value, accountId: account.id, reason: `Completed quest: ${quest.displayTitle}` })
    }

    account?.save()
  }
}