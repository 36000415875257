import Parse from 'parse';
import mixpanel from "mixpanel-browser";
import { useState } from "react";
import { ChevronLeft } from 'react-feather';
import LoadingComponent from '../components/LoadingComponent';
import { useSeamUser } from '../utils/SeamUserContext';
import ProfilePhotoPage from './ProfilePhotoPage';
import ProfileBlockPage from './ProfileBlockPage';
import { Button, CircularProgress } from '@mui/material';

interface OnboardingTutorialProps { }

export default function MobileOnboardingTutorial({ }: OnboardingTutorialProps) {
  const { setAccount } = useSeamUser();
  const Account = Parse.Object.extend("Account");
  const currentUser = Parse.User.current();
  const [activeStep, setActiveStep] = useState(0);

  const [displayName, setDisplayName] = useState("");
  const [username, setUsername] = useState("");
  const [validUsername, setValidUsername] = useState(false);
  const [bio, setBio] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const [savingUser, setSavingUser] = useState(false);

  const saveUser = () => {
    if (currentUser === undefined) {
      alert("Your session has expired - please login again.");
      return;
    }
    setSavingUser(true);

    // let usernames and handles be identical for now
    // When users sign up, they'll get the url of their username as their profile
    let newHandle = username;
    let chosenProfilePic = profilePic;

    const account = new Account();
    account.save({
      userId: currentUser.id,
      profileId: newHandle,
      name: displayName,
      username: username,
      bio: bio,
      profilePhoto: chosenProfilePic,
      sp: -1, // New accounts get SP from their referrals and this tags it as a new account
      referrals: 0,
      quests: {},
      referredBy: undefined,
      referralCode: username,
      friendCount: 0,
      isOnboarded: false,
    }).then(async (newAccount: Parse.Object) => {
      mixpanel.track("account created");
      // Setting the account will transition in the InternalPage to the FirstTimeUnlock
      setTimeout(() => {
        setAccount(newAccount);
      }, 2000);
    }, (error: { message: any; }) => {
      console.log(error.message);
    });
  };

  const loadingPage = () => {
    console.log("rendering loading page");
    return (
      <LoadingComponent />
    )
  }

  const pageForState = () => {
    switch (activeStep) {
      case 0: return <ProfileBlockPage
        setValidUsername={setValidUsername}
        setUsername={setUsername}
        setDisplayName={setDisplayName}
        username={username}
      />
      case 1: return <ProfilePhotoPage
        profilePic={profilePic}
        setProfilePic={setProfilePic}
      />
      case 2: return loadingPage();
      default: return <h1>Error</h1>;
    }
  };

  const titleForPage = () => {
    switch (activeStep) {
      case 0: return "Claim your handle";
      case 1: return "Add a profile picture";
      default: return "";
    }
  };

  const handleNext = () => {
    if (activeStep === 1 && !profilePic) {
      alert("Please select a profile picture to continue.");
      return;
    }
    if (activeStep === 1 && currentUser) {
      saveUser();
    }
    else {
      setActiveStep((prevActiveStep) => {
        return prevActiveStep + 1;
      });
    }
    mixpanel.track("ios onboarding: page" + (activeStep + 1));
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const canAdvance = () => {
    switch (activeStep) {
      case 0: return validUsername && username !== ""
      case 1: return profilePic !== ""; // can advance if profile picture is selected
      case 2: return false;
      default: return false;
    }
  };

  const canGoBack = () => {
    switch (activeStep) {
      case 0: return false; // profile handle selection
      case 1: return true; // profile photo
      default: return false;
    }
  };

  const advanceEnabled = canAdvance();
  const backEnabled = canGoBack();
  const creatingProfile = activeStep < 3;

  return (
    <div style={{marginTop: 'env(safe-area-inset-top)'}} className="flex flex-col w-auto h-full px-4 bg-white justify-between">
      {/* Header Row */}
      <div className="flex flex-row justify-between items-center w-auto h-[54px]">
        {backEnabled ?
          <button
            className="rounded-full p-1 bg-[#EFEFEF]"
            onClick={handleBack}>
            <ChevronLeft size={24} color={"#101010"} />
          </button> :
          <div className="w-[30px] h-[30px]"></div>
        }

        <h3> {titleForPage()} </h3>

        <div className="w-[30px] h-[30px]"></div>
      </div>

      {/* Page Content with Scrolling */}
      <div className="h-full w-full flex flex-col overflow-hidden">
        {pageForState()}
      </div>

      {activeStep == 0 &&
        <h4 style={{marginBottom: '16px'}} className="text-gray-500 text-center"> by continuing you agree to the Seam <a href="https://getseam.xyz/information-pages/community-guidelines" target="_blank" rel="noopener noreferrer" className="text-seam-blue"> terms </a> </h4>}
      
      {/* Continue Button */}
      {creatingProfile && (
        <Button
          style={{marginBottom: 'calc(50px + env(safe-area-inset-bottom))'}}
          className={`w-full h-[56px] rounded-[8px] font-[16px] ${!advanceEnabled ? 'bg-[#EFEFEF] cursor-not-allowed text-seam-black/50' : 'bg-seam-blue text-seam-white hover:bg-seam-green'}`}
          disabled={!advanceEnabled || savingUser}
          onClick={handleNext}>
          {savingUser ? <CircularProgress color="inherit" size={24} /> : 'Continue'}
        </Button>
      )}
    </div>
  );
}