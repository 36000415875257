import React, { memo, useEffect, useState } from 'react';
import SeamExpandableListHeaderBar from '../components/SeamExpandableListHeaderBar';
import { useSeamNavigator } from '../Navigation/SeamNavigatorContext';
import { Check } from 'react-feather';
import { Divider } from '@mui/material';
import ComposerMiniAppItem from '../Block-SDK/src/Composer/ComposerMiniAppItem';
import QuestManager from '../Quests/QuestManager';
import { BlockTypes } from '../Block-SDK/src/blocks/types';
import mixpanel from 'mixpanel-browser';
import { useMobile } from '../utils/MobileContext';

class DailyQuestsCache {
  static quests = null;
}

const DailyQuestsBar = ({ account }) => {
  const seamNavigator = useSeamNavigator();
  const [dailyQuests, setDailyQuests] = useState([]);
  const [completedQuests, setCompletedQuests] = useState(new Set());
  const { isMobile } = useMobile();

  useEffect(() => {
    const fetchDailyQuests = async () => {
      if (DailyQuestsCache.quests) {
        setDailyQuests(DailyQuestsCache.quests);
      } else {
        const quests = await QuestManager.getDailyQuests();
        DailyQuestsCache.quests = quests;
        setDailyQuests(quests);
      }
    };

    fetchDailyQuests();
  }, []);

  useEffect(() => {
    const fetchCompletedQuests = () => {
      const quests = account?.get("dailyQuests") || [];
      setCompletedQuests(new Set(quests));
    };

    fetchCompletedQuests();
  }, [account]);

  // Keeping this around as an example of a banner
  function MiniappChallengeCard() {
    return (
      <div className="flex items-center justify-center bg-gradient-to-r from-blue-500 to-purple-500 rounded-[24px]" onClick={() => window.emitter.emit("SEAM_POST_BLOCKTYPE_CLICK", undefined)}>
        <div className="w-[300px] rounded-[24px] flex flex-col items-center justify-center text-center p-4">
          <h3 className="text-white mb-2 font-semibold">Vote in the Miniapp Challenge!</h3>
          <p className="text-white text-sm mb-4">Try the 13 new miniapps</p>
          <button className="w-full bg-lime-400 text-blue-900 py-2 mt-8 mx-4 rounded-lg">
            Unlock to Vote
          </button>
        </div>
      </div>
    );
  }

  const handleOpenComposer = (blockType) => {
    mixpanel.track("Daily quest tapped");
    window.emitter.emit("SEAM_POST_BLOCKTYPE_CLICK", blockType);
  };

  const loadingSkeleton = (
    Array(2).fill().map((_, index) => (
      <div
        key={index}
        className="flex flex-row items-start ml-4 p-1 justify-start max-w-[300px] rounded-[24px] bg-seam-black/10 gradient-border cursor-pointer"
      >
        <div className="m-4 flex flex-col w-[300px] h-[140px] justify-between">
          <div className="bg-gray-200 h-20 w-full rounded mb-4 animate-pulse"></div>
          <div className="bg-gray-200 h-4 w-3/4 rounded mb-2 animate-pulse"></div>
          <div className="bg-gray-200 h-4 w-1/2 rounded animate-pulse"></div>
        </div>
      </div>
    ))
  );

  const tapMiniApp = (type) => {
    let blockType = BlockTypes[type];
    if (!blockType) {
      alert("Update your app to use the new miniapp!");
      return;
    } else {
      handleOpenComposer(type);
    }
  };

  return (
    <div className="flex flex-col w-full max-w-[720px] self-center h-full">
      <div className="flex flex-row justify-between items-center w-full mb-4">
        <div className="pl-4">
          <SeamExpandableListHeaderBar
            title="Daily Featured Miniapp Quests"
            expandTo={() => { }}
          />
        </div>
      </div>

      <div className="overflow-x-auto">
        <div className={`flex flex-row overflow-x-scroll space-x-4 hide-scrollbar ${isMobile ? 'px-4' : 'w-auto justify-center'}`}>
          {dailyQuests.length === 0 && loadingSkeleton}
          {dailyQuests.map((quest, index) => {
            const blockType = quest.get("BlockType");
            const isCompleted = completedQuests.has(quest.get("tag"));

            return (
              <div
                key={index}
                className="flex flex-row items-start p-1 max-w-[300px] justify-start rounded-[24px] bg-seam-black/10 gradient-border cursor-pointer"
                onClick={() => tapMiniApp(blockType)}
              >
                <div className="m-4 flex flex-col w-[300px] justify-between">
                  <ComposerMiniAppItem
                    block={BlockTypes[blockType]}
                    tapAction={() => { }}
                  />
                  <Divider className='w-full my-1' />
                  <div className="flex flex-col items-start h-full justify-between">
                    <h4 className="text-seam-black mb-2 truncate-2-lines">{quest.get("description")}</h4>
                    <div className="flex flex-row items-center justify-between w-full h-auto mt-2">
                      <div className="flex items-center bg-seam-green text-seam-blue rounded px-3 py-1 mr-2">
                        <h4 className="text-seam-blue">#{quest.get("tag")}</h4>
                      </div>
                      <div className={`flex items-center ${isCompleted ? 'bg-seam-pink rounded-full' : 'bg-seam-green rounded'} text-center px-3`}>
                        <span className="flex flex-row items-center justify-center">
                          <h4 className={`${isCompleted ? 'text-seam-white' : 'text-seam-black'} text-center`}>{isCompleted ? 'Completed' : `Earn +${quest.get("reward") * 3}`}</h4>
                          &nbsp;
                          {!isCompleted && <img className="w-4 h-4" src="https://upcdn.io/W142hWW/raw/Seam%20Assets/Seam%20Point.png" />}
                        </span>
                        {isCompleted && <Check className="ml-2" size={16} color="white" />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default memo(DailyQuestsBar);