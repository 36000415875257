import { memo } from 'react';
import UserProfilePage from './Profile/UserProfilePage';
import { useSeamUser } from './utils/SeamUserContext';
import WelcomeFromWebCreatedAccountPage from './components/WelcomeFromWebCreatedAccountPage';

function MyProfilePage() {
  const { account } = useSeamUser();

  if (account && account.get("isOnboarded") !== true) {
    //resurrect old users here

    return <WelcomeFromWebCreatedAccountPage />
  }

  return (
    <UserProfilePage username={account?.get('profileId')} isSelfProfile={true} />
  );
};

export default memo(MyProfilePage);