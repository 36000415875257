import React from 'react';
import { ChevronRight } from 'react-feather';
const SeamExpandableListHeaderBar = ({title, subtitle, expandTo}) => (
<div className="flex justify-between items-center w-full cursor-pointer" onClick={expandTo}>
  <div className="flex items-center">
    <h4>{title}</h4>
    {subtitle && <h4 className="text-seam-gray-subtitle pl-1 pr-1">•</h4>}
    {subtitle && <h4 className="text-seam-gray-subtitle">{subtitle}</h4>}
  </div>
  {/* {expandTo && <ChevronRight onClick={expandTo}/>} */}
</div>
);

export default SeamExpandableListHeaderBar;