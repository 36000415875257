import { makeStyles } from "@mui/styles";
import Parse from "parse";

import { useEffect, useState } from "react";
import LoginComponent from "./components/LoginComponent";
import { Dialog, Typography, Box } from "@mui/material";
import LoadingComponent from "./components/LoadingComponent";
import OnboardingTutorial from "./OnboardingTutorial";
import { useHistory, useLocation } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { IonContent, IonPage } from "@ionic/react";
import { useSeamUser } from "./utils/SeamUserContext";
import { motion } from "framer-motion"
import { Seo } from "./utils/Seo";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    display: "flex",
    flexFlow: "row nowrap",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      // for screens smaller or equal to 'sm' size
      flexDirection: "column", // stack on top of each other
    },
    height: "100vh",
    width: "100%",
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    overflow: "hidden",
  },
}));

const floatAnimation = (yInitial: number, yFinal: number, duration: number, delay: number) => ({
  animate: {
    y: [yInitial, yFinal, yInitial], // Cycle from yInitial to yFinal and back to yInitial
  },
  transition: {
    y: {
      repeat: Infinity,
      repeatType: "loop", // Ensure it loops continuously
      duration: duration,
      ease: "easeInOut", // Smooth transition for a more natural float
      delay: delay // Delay the start of the animation
    }
  }
});

export default function LandingPage() {
  const classes = useStyles();
  const history = useHistory();
  const [mobileDesktop, setMobileDesktop] = useState(window.innerWidth < 768);
  const [isLoading, setIsLoading] = useState(false);
  const [header, setHeader] = useState("Welcome to Seam");
  const [subtitle, setSubtitle] = useState("Connect and collect with friends");
  const [referrer, setReferrer] = useState<Parse.Object | null>(null);
  const { account, isNewUser } = useSeamUser();
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const handleSlideChange = (swiper: any) => {
    setCurrentSlideIndex(swiper.activeIndex); 
  };

  useEffect(() => {
    const handleResize = () => {
      setMobileDesktop(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    window.emitter.on("SEAM_EVENT_IS_LOADING", (loading) => {
      setIsLoading(loading);
    });
    mixpanel.track("landing page hit");
  }, []);

  // Get the referral code, and if necessary, find the name of the referrer to customize the CTA
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const referredBy = params.get("ref");
  const hasReferral = referredBy != null
  useEffect(() => {
    if (hasReferral) {
      const query = new Parse.Query("Account");
      query.equalTo("referralCode", referredBy);
      query.first().then((result) => {
        if (result != undefined) {
          setReferrer(result);
          let friendName = result.get("name");
          setHeader(friendName + " invites you to Seam!");
          setSubtitle(friendName + " wants you to join them on Seam!");
        }
      });
    }
  }, []);

  useEffect(() => {
    if (account && Parse.User.current()) {
      history.replace(`/user/${account.get("profileId")}`);
    }
  }, [account]);

  if (isNewUser) {
    return (
      <div className={classes.root}>
        <OnboardingTutorial referredBy={referrer} />
      </div>
    )
  }

  const LogoComponent = () => (
    <div className="w-[10rem] mt-[30px] h-auto mx-auto flex items-center justify-center">
      <img className="w-full h-auto" src={"https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/WhiteLogo.svg?alt=media"} alt="Seam Logo" />
    </div>
  );

  const ImageComponent = ({src}: { src: any} ) => {
    return (
      <img
        src={src}
        className={`${mobileDesktop ? 'max-w-[90px]' : 'max-w-[150px]'} w-full h-auto`}
      />
    )
  }

  const ImageContainerSlide1: React.FC = () => (
    <div className="w-full h-auto flex items-center justify-center">
      <img
        src={"https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/Profile_1.png?alt=media"}
        className="place-self-end max-w-[140px] h-auto rotate-[8deg] mb-[15%] z-2"
      />
      <img
        src={"https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/Profile_2.png?alt=media"}
        className="self-center max-w-[140px] h-auto -rotate-1 mb-[20%] z-3"
      />
      <img
        src={"https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/Profile_3.png?alt=media"}
        className="self-center max-w-[140px] h-auto -rotate-[20deg] z-4"
      />
    </div>
  );
  
  const ImageContainerSlide2: React.FC = () => (
    <>
      <img 
        src={"https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/LandingSlide2.png?alt=media"}
        className="self-center max-w-[400px] h-auto"
      />
    </>
  );
  
  const ImageContainerSlide3: React.FC = () => (
    <div className="flex flex-row w-full justify-between h-full space-x-6">
      <div className="flex flex-col items-start justify-start space-y-6 h-auto">
        <ImageComponent src={"https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/LandingSlide3_1.png?alt=media"} />
        <ImageComponent src={"https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/LandingSlide3_2.png?alt=media"} />
      </div>
      <div className="flex flex-col items-center justify-center space-y-6 h-auto">
      <ImageComponent src={"https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/LandingSlide3_3.png?alt=media"} />
      <ImageComponent src={"https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/LandingSlide3_4.png?alt=media"} />
      </div>
      <div className="flex flex-col items-end justify-end space-y-6 h-auto">
        <ImageComponent src={"https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/LandingSlide3_5.png?alt=media"} />
        <ImageComponent src={"https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/LandingSlide3_6.png?alt=media"} />
      </div>
    </div>
  );

  const SwiperContent = ({
    headerString,
    subtitleString,
    ImageContainer
  }: {
    headerString: string;
    subtitleString: string;
    ImageContainer: React.FC;
  }) => {
    if (hasReferral) {
      headerString = header;
    }
    
    return mobileDesktop ? (
      // Mobile version
      <div className="flex flex-col max-w-[1080px] mx-auto px-[24px] text-center items-center justify-between h-full w-auto">
        <div className="w-full h-auto flex flex-col items-center justify-center space-y-6">
          <Typography variant="h1" className="h-auto w-full text-seam-white">
            {headerString}
          </Typography>
          <Typography variant="h2" className="h-auto w-full text-seam-white">
            {subtitleString}
          </Typography>
        </div>
        <div className="w-full flex relative items-start justify-center h-full py-4 overflow-visible">
          <ImageContainer />
        </div>
      </div>
    ) : (
      // Desktop version
      <div className="flex flex-row max-w-[1080px] mx-auto px-[64px] items-center justify-center h-full w-auto space-x-[2.5rem]">
        <div className="w-[40%] h-auto flex-col items-start justify-center py-4 space-y-4">
          <Typography variant="h1" className="h-auto w-full text-seam-white">
            {headerString}
          </Typography>
          <Typography variant="h2" className="h-auto w-full text-seam-white">
            {subtitleString}
          </Typography>
        </div>
        <div className="w-[60%] flex relative items-center justify-center h-full overflow-visible">
          <ImageContainer />
        </div>
      </div>
    );
  };

  return (
    <IonPage>
      <Seo
        title={"Seam Social"}
        description={"Create & Discover miniapps on Seam"}
        author={"@seam_xyz"}
        imageURL={"https://www.seam.so/seam-logo.png"}
        url={"https://www.seam.so/"}
      />
      <IonContent fullscreen={true} scrollY={false}>
        <div className="absolute flex flex-row h-full w-full grow">
          <div
            className="flex flex-col h-full w-full justify-center items-between"
            style={{
              background:
                "linear-gradient(168deg, #0051E8 -11.45%, #DD3BF7 182.62%)",
            }}
          >
            <motion.div
              className="horizontal-lines-desktop"
              initial={{ y: 0 }}
              animate={{ y: "-100%" }}
              transition={{ ease: "linear", duration: 120, repeat: Infinity }}
            />
            <motion.div
              className="vertical-lines-desktop"
              initial={{ x: "0%" }}
              animate={{ x: "-50%" }}
              transition={{ ease: "linear", duration: 80, repeat: Infinity }}
            />

            <div className="relative flex flex-col h-full w-full items-start justify-between overflow-hidden">
              <div className="w-full h-auto">
                <LogoComponent />
              </div>

              <div className={`flex-1 w-full h-full flex justify-center items-center ${!mobileDesktop && 'my-6' }`}>
                <Swiper
                    spaceBetween={200}
                    pagination={{ clickable: true }}
                    loop={true}
                    autoplay={{ delay: 3000, disableOnInteraction: false }}
                    modules={[Autoplay, Pagination]}
                    onSlideChange={handleSlideChange}
                    className={`swiper-container w-full ${mobileDesktop ? 'h-full max-h-[600px]' : 'h-full'}`}
                  >
                    <SwiperSlide className="h-full w-full flex flex-col items-center justify-center">
                      <SwiperContent
                        headerString="Stitch the Internet Together"
                        subtitleString="Craft a home for your creation"
                        ImageContainer={ImageContainerSlide1}
                      />
                    </SwiperSlide>
                    <SwiperSlide className="h-full w-full flex flex-col items-center justify-center">
                      <SwiperContent
                        headerString="Curate Inspiration"
                        subtitleString="Organize ideas by following and collecting"
                        ImageContainer={ImageContainerSlide2}
                      />
                    </SwiperSlide>
                    <SwiperSlide className="h-full w-full flex flex-col items-center justify-center">
                      <SwiperContent
                        headerString="Infinite ways to create"
                        subtitleString="New miniapps launching all the time built by the Seam community"
                        ImageContainer={ImageContainerSlide3}
                      />
                    </SwiperSlide>
                  </Swiper>
              </div>

              <Box className="bg-transparent flex items-center justify-center w-auto" style={{ paddingBottom: 'calc(env(safe-area-inset-bottom, 24px) + 24px)', paddingTop: 'calc(env(safe-area-inset-bottom, 24px) + 24px)' }} sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1301 }}>
                <div className="w-full h-auto flex items-center justify-center max-w-[300px]">
                  <LoginComponent title={header} subtitle={subtitle} referralAccount={referrer} />
                </div>
              </Box>
            </div>
            
            <Dialog open={isLoading} onClose={() => setIsLoading(false)}>
                <LoadingComponent />
            </Dialog>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}
