import React, { useEffect, useState } from "react";
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import SizeAwareComponent from "../utils/SizeAwareComponent";
import { withSize } from "react-sizeme";
import Parse from "parse";
import { useSeamNavigator } from "../Navigation/SeamNavigatorContext";
import { useMobile } from "../utils/MobileContext";
import { Typography } from "@mui/material";
import ProfilePageHeader from "./ProfilePageHeader";
import { LibraryAddOutlined } from '@mui/icons-material';
import SeamChannelRepository from '../Channels/SeamChannelRepository';
import { useSeamUser } from "../utils/SeamUserContext";

function CollectionsFeed({ size: { width }, creatorAccount, profileCustomizations }) {
  const [channels, setChannels] = useState([]);
  const { account } = useSeamUser();
  const { isMobile } = useMobile();
  const seamNavigator = useSeamNavigator();
  const isOwnProfile = account?.id === creatorAccount?.id

  useEffect(() => {
    if (!creatorAccount) return;
    const query = new Parse.Query("Channel");
    query.equalTo("creator", creatorAccount);
    query.equalTo("isDeleted", false);
    query.descending("createdAt");
    query.find().then((results) => {
      setChannels(results);
    });
  }, [creatorAccount]);

  useEffect(() => {
    const handleCollectionCreated = (event) => {
      setChannels((prevChannels) => [...prevChannels, event.collection]);
    };
  
    const handleCollectionDeleted = (event) => {
      setChannels((prevChannels) =>
        prevChannels.filter((channel) => channel.id !== event.channelId)
      );
    };
  
    window.emitter.on("SEAM_EVENT_CREATED_COLLECTION", handleCollectionCreated);
    window.emitter.on("SEAM_EVENT_DELETED_COLLECTION", handleCollectionDeleted);
  
    return () => {
      window.emitter.off("SEAM_EVENT_CREATED_COLLECTION", handleCollectionCreated);
      window.emitter.off("SEAM_EVENT_DELETED_COLLECTION", handleCollectionDeleted);
    };
  }, []);  

  const textColor = profileCustomizations?.get("fontColor") || "black";

  const handleAddCollection = () => {
    const channel = SeamChannelRepository.emptyChannel({
      creator: creatorAccount,
      name: "New Collection",
      emoji: "😊",
      headerImage: "",
    })
    seamNavigator.navigateTo(`/collection/${channel?.id}`, channel);
  };

  const layout = [];

  if (account?.id === creatorAccount?.id) {
    layout.push({ i: "add", x: 0, y: 0, w: 1, h: 1 });
  }
  
  const startIndex = account?.id === creatorAccount?.id ? 1 : 0;
  
  channels.forEach((item, index) => {
    layout.push({
      i: index.toString(),
      x: (index + startIndex) % 2, 
      y: Math.floor((index + startIndex) / 2),
      w: 1,
      h: 1,
    });
  });
  
  layout.push({ i: "empty", x: 0, y: Math.floor((channels.length + startIndex) / 2) + 1, w: 2, h: 1 });  

  return (
    <div className="h-full w-full flex mt-4 items-start justify-start">
      <ResponsiveGridLayout
        className="layout hide-scrollbar h-auto w-full"
        layouts={{ lg: layout }}
        breakpoints={{ lg: 768, xxs: 0 }}
        cols={{ lg: 2, xxs: 2 }}
        rowHeight={width / 1.9}
        width={width}
        isDraggable={false}
        isResizable={false}
        measureBeforeMount={true}
        margin={[32, 16]}
      >
        {account?.id === creatorAccount?.id && (
          <div
            key="add"
            onClick={handleAddCollection}
            className="widget relative cursor-pointer flex flex-col h-full rounded-[12px] overflow-hidden"
          >
            <div className={`absolute inset-0 flex flex-row items-center mb-[58px] space-x-2 rounded-[12px] justify-center`}>
              <LibraryAddOutlined className={`text-${textColor} h-4 w-4`} />
              <Typography variant="h3" className={`text-${textColor}`}>New</Typography>
            </div>
            <div
              className={`border-[1px] border-${textColor}/10`}
              style={{
                backgroundColor: textColor,
                opacity: 0.5,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                width: '100%',
                paddingTop: '100%',
                borderRadius: '12px',
                flexShrink: 0,
                boxSizing: 'border-box',
              }}
            >
              {/* The background image container */}
            </div>
          </div>
        )}
  
        {channels.map((channel, index) => {
          const backgroundImageUrl = channel?.get("headerImage");
          const emoji = channel?.get("emoji");
          const name = channel?.get("name");
          const numPosts = channel?.get("numPosts");
  
          return (
            <div
              key={index.toString()}
              className="widget relative cursor-pointer flex flex-col h-full rounded-[12px] overflow-hidden"
              onClick={() => seamNavigator.navigateTo(`/collection/${channel.id}`, channel)}
            >
              {!backgroundImageUrl && isOwnProfile && 
                <div className={`absolute inset-0 flex items-center mb-[58px] rounded-[12px] justify-center`}>
                  <Typography variant="h3" className={`text-${textColor}`}>Add a cover</Typography>
                </div>
              }
              <div
                className={`border-[1px] border-${textColor}/10`}
                style={{
                  backgroundColor: backgroundImageUrl ? 'transparent' : textColor, 
                  opacity: backgroundImageUrl ? 1 : 0.5,
                  backgroundImage: `url(${backgroundImageUrl})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  width: '100%',
                  paddingTop: '100%',
                  borderRadius: '12px',
                  flexShrink: 0,
                  boxSizing: 'border-box',
                }}
              >
                {/* The background image container */}
              </div>
              <div
                style={{ color: textColor }}
                className="w-full h-full flex flex-col items-center justify-center py-2 text-center"
              >
                <div className="flex space-x-2 h-auto items-center overflow-hidden">
                  <span className="h-auto">{emoji}</span> 
                  <Typography className="truncate flex-grow text-start" variant={isMobile ? "h4" : "h3"}>
                    {name}
                  </Typography>
                </div>
                <Typography className="whitespace-nowrap ml-4 flex-shrink-0" variant={isMobile ? "h4" : "h3"}>
                  {numPosts} posts
                </Typography>
              </div>
            </div>
          );
        })}

        {/* Empty Div for Bottom Space */}
        <div key="empty" className="widget" style={{ width: '100%', height: '100%' }} />
      
      </ResponsiveGridLayout>
    </div>
  );  
}

export default withSize({ refreshMode: "debounce", refreshRate: 60 })(
  CollectionsFeed
);