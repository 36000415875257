import { Mention, MentionsInput } from 'react-mentions';
import { Avatar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Parse from 'parse';
import { memo, useEffect, useRef, useState } from 'react';


const useStyles = makeStyles(() => ({
  resultItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 10px',
    backgroundColor: '#f9f9f9',
    '&:hover': {
      backgroundColor: '#e6e6e6',
    },
    padding: '10px 16px',
  },
  username: {
    fontWeight: 'bold',
    fontFamily: 'Public Sans, sans-serif',
    fontSize: '16px',
  },
  resultText: {
    marginLeft: '10px',
    fontFamily: 'Public Sans, sans-serif',
    color: 'black',
    fontSize: '16px',
  },
  mentions: {
    border: '1px solid #ccc',
    fontSize: '16px',
    fontWeight: 'normal',
    fontFamily: "Public Sans, sans-serif",
    padding: '8px 12px',
    borderRadius: '4px',
    display: 'block',
    width: '100%',
    resize: 'none',
    minHeight: '80px',
  },
  mentionsInput: {
    border: '0',
    outline: 'none',
    width: '100%',
  },
  suggestionsList: {
    position: 'absolute',
    zIndex: 1000,
    backgroundColor: 'white',
    fontSize: 16,
  },
  suggestionItem: {
    padding: '5px 15px',
    borderBottom: '1px solid rgba(0,0,0,0.15)',
    '&focused': {
      backgroundColor: '#cee4e5',
    },
    zIndex: 1000,
  },
}));

const searchUsers = async (query, callback) => {
  try {
    const results = await Parse.Cloud.run('searchUsers', { searchTerm: query });
    callback(results.map(user => ({
      id: user.userId,
      display: user.username,
      name: user.name,
      profilePhoto: user.profilePhoto
    })));
  } catch (error) {
    console.error('Error during search:', error);
  }
};

const MentionSearch = ({ textValue, mentionList, onChange, addMention, placeholder = "", inputRef = null, onSubmit }) => {
  const classes = useStyles();

  const handleKeyDown = (event) => {
    // Check if Enter is pressed without Shift
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); // Prevent default to stop the newline
      onSubmit(); // Call the provided submit function
    }
  };

  const handleAddMention = (mentionId) => {
    if (mentionList.length > 10) {
      console.warn("Maximum of 10 mentions reached");
      return;
    }

    const uniqueMentions = new Set(mentionList.map(mention => mention.id));

    if (uniqueMentions.has(mentionId)) {
      console.warn("This user is already mentioned");
      return;
    }

    addMention(mentionId);
  };

  return (
    <div className="w-full">
      <MentionsInput
          value={textValue}
          onChange={onChange}
          placeholder={"What's on your mind?"}
          className={classes.mentionsInput}
          inputRef={inputRef}
          onKeyDown={handleKeyDown}
          classNames={{
            suggestions: classes.suggestionsList,
            suggestion: classes.suggestionItem,
          }}
          allowSuggestionsAboveCursor={true}
          customSuggestionsContainer={(children) => <div style={{ border: '2px solid black' }}>{children}</div>}
        >
        <Mention
          trigger="@"
          markup="@[__display__]"
          data={searchUsers}
          onAdd={handleAddMention}
          renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => (
            <div className={classes.resultItem}>
              <Avatar alt={suggestion.name} src={suggestion.profilePhoto} />
              <div className={classes.resultText}>
                <Typography variant="subtitle2" className={classes.username}>@{suggestion.display}</Typography>
                <Typography variant="body2">{suggestion.name}</Typography>
              </div>
            </div>
          )}
          displayTransform={(_, display) => `@${display}`}
        />
      </MentionsInput>
    </div>
  )
};

export default memo(MentionSearch);