import React, { useState, useEffect } from "react";
import { useMobile } from "../utils/MobileContext";
import { Bell } from "react-feather";
import { ReactComponent as HomeIcon } from "../assets/icons/homeIcon2.svg";
import { Avatar } from "@mui/material";
import Parse from "parse";
import { getProcessedProfilePictureURL } from "../components/helpers/ImageTransformers";
import { useSeamUser } from "../utils/SeamUserContext";
import { Capacitor } from "@capacitor/core";
import { IonTabButton, IonTabBar, IonTabs, useIonRouter } from "@ionic/react";
import { motion } from "framer-motion";
import "./MobileBottomNav.css";

const tabVariants = {
  hidden: { scale: 0 },
  visible: { scale: 1 },
};

export default function MobileBottomNav({ children }) {
  const [keyboardShowing, setKeyboardShowing] = useState(false);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [currentTab, setCurrentTab] = useState("profile");
  const { isMobile, isMobileApp } = useMobile();
  const ipadsAndMobiles = isMobile || isMobileApp;
  const router = useIonRouter();
  const { account, hasUnreadNotifications } = useSeamUser();
  const isAuthenticated = Parse.User.current() != undefined;
  const currentAccountProcessedProfilePhoto = getProcessedProfilePictureURL(
    account?.get("profilePhoto")
  );
  const hideBottomNav =
    router.routeInfo.pathname.includes("/post/") ||
    router.routeInfo.pathname.includes("/comment/");

  useEffect(() => {
    if (Capacitor.getPlatform() === "web") {
      return;
    }

    const handleKeyboardDidShow = (ev) => {
      setKeyboardShowing(true);
    };

    const handleKeyboardDidHide = () => {
      setKeyboardShowing(false);
    };

    window.addEventListener("ionKeyboardDidShow", handleKeyboardDidShow);
    window.addEventListener("ionKeyboardDidHide", handleKeyboardDidHide);

    return () => {
      window.removeEventListener("ionKeyboardDidShow", handleKeyboardDidShow);
      window.removeEventListener("ionKeyboardDidHide", handleKeyboardDidHide);
    };
  }, []);

  useEffect(() => {
    window.emitter.on("SEAM_EVENT_TOGGLE_MODE", (mode) => {
      setIsInEditMode(mode === "edit");
    });
  }, []);

  const hideMobileTabs =
    !isAuthenticated ||
    !ipadsAndMobiles ||
    account == null ||
    account.get("isOnboarded") === undefined ||
    account.get("isOnboarded") === false;
  if (hideMobileTabs) {
    return children;
  }

  const mobileTabsVisible = !isInEditMode && !keyboardShowing && !hideBottomNav;

  const profileNavButton = () => {
    return (
      <IonTabButton className="w-[60px] h-[60px]" tab="profile" href="/profileTab">
        <div className="relative flex justify-center items-center">
          <motion.div
            initial={currentTab === "profile" ? "visible" : "hidden"}
            animate={currentTab === "profile" ? "visible" : "hidden"}
            variants={tabVariants}
            transition={{ duration: 0.3 }}
            className="absolute bg-white rounded-full h-[50px] w-[50px]"
          />
          <Avatar
            src={currentAccountProcessedProfilePhoto}
            style={{
              width: 22,
              height: 22,
              border: currentTab === "profile" ? "2px solid black" : "2px solid white",
              zIndex: 1
            }}
          />
        </div>
      </IonTabButton>
    );
  };

  return (
    <IonTabs
      onIonTabsDidChange={(event) => {
        setCurrentTab(event.detail.tab);
      }}
      style={{ marginTop: "env(safe-area-inset-top)" }}
    >
      {children}
      <IonTabBar
        slot="bottom"
        style={{ visibility: mobileTabsVisible ? "visible" : "hidden", height: '60px', width: '180px', padding: 0 }}
        className="flex flex-row items-center justify-center"
      >
        <IonTabButton className="w-[60px] h-[60px]" href={ipadsAndMobiles ? "/homeTab" : "/home"} tab="home">
          <div style={{ position: "relative", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <motion.div
              initial={currentTab === "home" ? "visible" : "hidden"}
              animate={currentTab === "home" ? "visible" : "hidden"}
              variants={tabVariants}
              transition={{ duration: 0.3 }}
              className="bg-white absolute h-[50px] w-[50px] rounded-full"
            />
            <HomeIcon fill={currentTab === "home" ? "black" : "white"} style={{ zIndex: 1 }} />
          </div>
        </IonTabButton>
        {profileNavButton()}
        <IonTabButton className="w-[60px] h-[60px]" href={ipadsAndMobiles ? "/notificationsTab" : "/notifications"} tab="notifications">
          <div className="relative flex justify-center items-center">
            <motion.div
              initial={currentTab === "notifications" ? "visible" : "hidden"}
              animate={currentTab === "notifications" ? "visible" : "hidden"}
              variants={tabVariants}
              transition={{ duration: 0.3 }}
              className="bg-white absolute rounded-full h-[50px] w-[50px]"
            />
            {hasUnreadNotifications && (
              <div
                key="unread-dot"
                className="bg-seam-pink rounded-full p-[3px] absolute mb-0"
                style={{ marginTop: "-36px", zIndex: 1 }}
              />
            )}
            <Bell color={currentTab === "notifications" ? "black" : "white"} style={{ zIndex: 1 }} />
          </div>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
}